import {ExclamationCircleOutlined} from "@ant-design/icons";
import styled from "@emotion/styled";
import {Empty, Form, Modal, Select} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AdWordsLoading, AxiosContext, AxiosContextType, track} from "@front-component";

const {confirm} = Modal;


const Style = styled.div``

export function GoogleCustomers({onSelect}: { onSelect: Function }) {
    const query = new URLSearchParams(window.location.search);
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)

    async function deleteAccountAndLogout() {
        await api!.delete('/auth/orga');
        window.location.href = '/login';
    }

    async function load() {
        setLoading(true)
        try {
            const data = await api!.get("/google-ads-api/customers")
            setCustomers(data.data)
        } catch (e) {
            if (e.response.status === 403) {
                confirm({
                    okText: "Retry login",
                    cancelButtonProps: {disabled: true},
                    title: "We can't retrieve your campaign",
                    icon: <ExclamationCircleOutlined/>,
                    content: (
                        <div>
                            Please be sure to authorize GetRank
                        </div>
                    ),
                    async onOk() {
                        await deleteAccountAndLogout();
                    },
                });
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        load().then()
    }, [])

    async function onAccountChange(customerId) {
        const customer = customers.find(c => c.id === customerId)
        await onSelect(customer)
        track("ad-account-selected", customer)
    }

    return (
        <AdWordsLoading loading={loading}>
            <Style>
                <Fragment>
                    {customers.length == 0 && <Empty/>}
                    {customers.length > 0 &&
                    <Form>
                        <FormItem label={"Select an account"} name={"account"}
                                  rules={[{required: true}]}>
                            <Select
                                style={{minWidth:250}}
                                onChange={onAccountChange}>
                                {customers.map(c => (
                                    <Select.Option key={c.id} disabled={c.id === 0}
                                                   value={c.id}>{c.descriptiveName || c.id}</Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Form>
                    }
                </Fragment>
            </Style>
        </AdWordsLoading>
    )
}
