/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import {jsx} from '@emotion/react';
import {Card} from "antd";
import React from "react";
import {GoogleSettings} from "./GoogleSettings";

const Style = styled.div``

export function GoogleSettingsPage(){

    return <Style>
        <Card title={"Adwords account"} bordered={false} >
            <span>Connect your Google Adwords account to automatically launch ads.</span>
            <br/>
            <br/>
            <GoogleSettings></GoogleSettings>
        </Card>
    </Style>
}

