import {AxiosInstance} from "axios";
import {ProtectionDTO} from "../lib";


export class  GoogleAPI{
    static getInstance: (api: AxiosInstance) => GoogleAPI;

    constructor(private api?: AxiosInstance) {
    }


    async getCampaignsNames(customerId?: number) {
        if(!customerId) throw new Error("customerId is required")
        const response = await this.api!.get("/google-ads-api/campaigns-names/" + customerId)
        return response.data
    }

    async getCampaign(campaignId?: number) {
        if(!campaignId) throw new Error("campaignId is required");
        const response = await this.api!.get("/google-ads-api/campaigns/" + campaignId)
        return response.data
    }
}

GoogleAPI.getInstance = (api: AxiosInstance) => {
    return new GoogleAPI(api)
}
