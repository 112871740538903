/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import {Button, Card, Checkbox, Form, Input} from "antd";
import React, {useContext} from "react";
import {BrandProtectionsList} from "@front-component";
import {MonitorThreats} from "../components/MonitorThreats";

const Style = styled.div``

export function MonitorThreatsPage({selectedProtectionId}:any) {

    return <Style>
        <Card title={"Looking for Competitors"} bordered={false} >
            <MonitorThreats selectedProtectionId={selectedProtectionId}/>
        </Card>
    </Style>
}

