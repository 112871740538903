/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Empty, Modal, notification} from "antd";
// @ts-ignore
import {useRoutes, navigate} from 'hookrouter';
import React, {Fragment, useContext, useEffect, useLayoutEffect, useState} from 'react';
import './App.css';
import {BillingCancelPage} from "./page/BillingCancelPage";
import {BillingPage} from "./page/BillingPage";
import {BillingSuccessPage} from "./page/BillingSuccessPage";
import {BrandProtectionActionsPage} from "./page/BrandProtectionActionsPage";
import {BrandProtectionEditPage} from "./page/BrandProtectionEditPage";
import {BrandProtectionsListPage} from "./page/BrandProtectionsListPage";
import {BrandProtectionThreatsPage} from "./page/BrandProtectionThreatsPage";
import {Checkout} from "./components/Checkout";
import {FreeCompetitorCheckInProgress} from "./components/free/FreeCompetitorCheckInProgress";
import {FreeCompetitorCheckInProgressPage} from "./components/free/FreeCompetitorCheckInProgressPage";
import {FreeCompetitorCheckPage} from "./components/free/FreeCompetitorCheckPage";
import {FreeCompetitorCheckResultPage} from "./components/free/FreeCompetitorCheckResultPage";
import {FreeCreditUsedPage} from "./components/free/FreeCreditUsedPage";
import {LoginPage} from "./page/LoginPage";
import {AdWordsStrictLoading, getConfig, GoogleLogout, handleError, useLoading} from "@front-component";
import {GoogleOAuthCallback} from "@front-component";
import {GooglePageContainer} from "@front-component";
import {GoogleSettingsPage} from  "@front-component";
import {MonitorThreatsPage} from "./page/MonitorThreatsPage";
import {NoAdsGoogleAccount} from "./components/NoAdsGoogleAccount";
import {SettingsChangePasswordPage} from "./page/SettingsChangePasswordPage";
import {AxiosContextProvider,  UserContext, UserContextProvider, useUser} from "@front-component";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,} from 'axios';
import wrapper from 'axios-cache-plugin';
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from 'axios-extensions';
import {get} from 'lodash';
import {PartnerSubscriberValidatePage} from "./page/PartnerSubscriberValidatePage";
import {SetupGuide} from "./components/SetupGuide";

function WithCustomer(props: { children }) {
    console.log("redraw WithCustomer")
    const {user, setUser} = useContext(UserContext)
    useEffect(() => {
        if (user?.account && !Object.keys(user?.account?.selectedGoogleCustomer || {})) {
            window.location.href = "/google/settings"
        }
    })

    if (!user || Object.keys(user?.account?.selectedGoogleCustomer || {})) {
        return (
            <Fragment>
                {props.children}
            </Fragment>
        );
    }
}

function App() {
    let api =  createInstance()
    api = configureAxiosInstance(api)

    function getAxiosWithCache(axiosInstance) {
        return wrapper(axiosInstance, {
            maxCacheSize: 5000,
            ttl: 120000,
            excludeHeaders: true,
            baseURL: '/api',
        });
    }

    function configureAxiosInstance(axiosInstance) {
        axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
            // Do something before request is sent
            config = {
                withCredentials: true,
                baseURL: '/api',
                ...config,
            };

            return config;
        }, (error: Error) => Promise.reject(error));

        axiosInstance.interceptors.response.use((response: AxiosResponse) => {
            return response
        }, (error: AxiosError) => {
            handleError('generic', error);
            if ((get(error, 'response.status') === 401)) {
                if (error.request.responseURL.indexOf('/me') === -1) {
                    if (!window.location.href.includes("login")) {
                        window.location.href = "/login"
                    }
                    return Promise.reject(error);
                }
                /* updateToken(undefined);
                notification.warning({
                    message: 'Session Expired',
                    description: 'Your session has expired. Would you like to be redirected to the login page?',
                    onClose: () => {
                        localStorage.removeItem('user');
                    },
                }); */
            } else if (get(error, 'response.status') === 402) {
                if (!window.location.href.includes("checkout")) {
                    window.location.href = "/checkout"
                }
                // ignore 402
            } else if (get(error, 'response.status') === 404) {
                // ignore 404
            } else if (get(error, 'response.status') === 406) {
                if (window.location.href.includes("google/settings")) {
                    window.location.href = "/no-ads-google-account"
                }
            } else if (get(error, 'response.status') === 422) {
                return Promise.reject(error);
            } else {
                notification.error({
                    message: 'Oups!',
                    description:
                        'Hmmmm, an error occured!',
                });
                return Promise.reject(error);
            }
            return undefined;
        });
        return axiosInstance;
    }

    function createInstance() {
        return axios.create({adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))});
    }

    return <AxiosContextProvider api={api}>
        <AppRoot/>
    </AxiosContextProvider>
}


function AppRoot() {
    const [user, setUser] = useState(undefined as any)
    const {doAction, loading} = useLoading(true)
    const {loadMe} = useUser({user, setUser, reset: () => setUser(undefined)});

    useEffect(() => {doAction(async () => {
        await loadMe()
    })}, [])

    return <AdWordsStrictLoading loading={loading}>
        {user?.impersonated && <Fragment>
            <div css={css`background-color: coral;padding: 1em; color: white;font-size:20px`}>
                <a css={css`text-align:center;color: white;font-size:20px;margin: auto`}
                   href={"/logout"}>{user.email+":  "}IMPERSONATE ON... CLICK TO LOGOUT</a>
            </div>
        </Fragment>}
        <UserContextProvider user={user}>
            <AppContent/>
        </UserContextProvider>
    </AdWordsStrictLoading>
}

function AppContent() {
    const {user} = useContext(UserContext);
    const routes = {
        '/': () => {
            if(user){
                navigate("/brand-protection/dashboard")
            }
            return <LoginPage/>
        },
        '/login': () => <LoginPage/>,
        '/guide': () => <AfterLogin><SetupGuide/></AfterLogin>,
        '/logout': () => <GoogleLogout/>,
        '/free-credit-used/:protectionId': ({protectionId}) => <FreeCreditUsedPage protectionId={protectionId}/>,
        '/free-competitor-check': () => <FreeCompetitorCheckPage/>,
        '/free-competitor-check/in-progress/:protectionId': ({protectionId}) => <FreeCompetitorCheckInProgressPage protectionId={protectionId}/>,
        '/free-competitor-check/result/:protectionId': ({protectionId}) => <FreeCompetitorCheckResultPage protectionId={protectionId}/>,

            '/no-ads-google-account': () => <NoAdsGoogleAccount/>,
        '/checkout': () => <AfterLogin><Checkout/></AfterLogin>,
        '/checkout/:priceId':({priceId}) => <AfterLogin><Checkout priceId={priceId}/></AfterLogin>,
        '/billing': () => <AfterLogin><BillingPage/></AfterLogin>,
        '/billing/success': () => <AfterLogin><BillingSuccessPage/></AfterLogin>,
        '/billing/cancel': () => <AfterLogin><BillingCancelPage/></AfterLogin>,
        '/google/settings': () => <OnLogin><GoogleSettingsPage/></OnLogin>,
        '/brand-protection/dashboard': () =>
            <AfterLogin><WithCustomer><BrandProtectionsListPage/></WithCustomer></AfterLogin>,
        '/brand-protection/threats/:protectionId': ({protectionId}) => <AfterLogin><WithCustomer><BrandProtectionThreatsPage
            selectedProtectionId={protectionId}/></WithCustomer></AfterLogin>,
        '/brand-protection/actions/:protectionId': ({protectionId}) => <AfterLogin><WithCustomer><BrandProtectionActionsPage
            selectedProtectionId={protectionId}/></WithCustomer></AfterLogin>,
        '/brand-protection/edit/:protectionId': ({protectionId}) => <AfterLogin><WithCustomer><BrandProtectionEditPage
            selectedProtectionId={protectionId}/></WithCustomer></AfterLogin>,
        '/brand-protection/edit/': () => <AfterLogin><WithCustomer><BrandProtectionEditPage
            selectedProtectionId={undefined}/></WithCustomer></AfterLogin>,
        '/brand-protection/monitor-competitors': () => <AfterLogin><WithCustomer><MonitorThreatsPage/></WithCustomer></AfterLogin>,
        '/brand-protection/monitor-competitors/:protectionId': ({protectionId}) => <AfterLogin><WithCustomer><MonitorThreatsPage
            selectedProtectionId={protectionId}/></WithCustomer></AfterLogin>,
        '/settings/validate-partner-subscriber': () => <PartnerSubscriberValidatePage/>,
        '/settings/change-password': () => <AfterLogin><SettingsChangePasswordPage/></AfterLogin>,
        '/google/oauth/callback': () =>   <UserContextProvider>
                <GoogleOAuthCallback/>
            </UserContextProvider>,
        '*': () => {
            if(user){
                navigate("/brand-protection/dashboard")
            }
            return <LoginPage/>
        },
    };
    const routeResult = useRoutes(routes);
    return (
        <div className="App">
            {routeResult || <Empty>Wrong URL</Empty>}
        </div>
    );
}


export default App;

function AfterLogin({children}) {
    const {user, setUser} = useContext(UserContext)
    console.log("redraw AfterLogin")
    useEffect(() => {
        // @ts-ignore
        $crisp.push(["set", "user:email", [user?.email]])
        // @ts-ignore
        $crisp.push(["set", "user:nickname", [user?.firstName?user?.firstName+" "+user?.lastName:user?.email]])
        // @ts-ignore
        $crisp.push(["set", "user:company", [user?.account?.selectedGoogleCustomer?.descriptiveName]])

    },[])

    if(user.role?.toLowerCase() === "partner"){
        Modal.info({
            title: 'Welcome to GetRank Partner',
            content: (
                <div>
                    <h1>You are a Partner now!.</h1>
                    All your old protections have been deleted <br/>
                    and you have to create new ones <br/>
                    by adding your first account on the <strong>Partner Platform</strong>.
                    <br/>
                    <br/>
                </div>
            ),
            onOk() {
                window.location.href = `${getConfig()?.partnerFrontBaseUrl}/accounts/add`
            },
        });
        }

    return <GooglePageContainer>
            <Fragment>
                {children}
            </Fragment>
    </GooglePageContainer>
}


function OnLogin({children}) {
    console.log("redraw OnLogin")
    return <GooglePageContainer>
        {children}
    </GooglePageContainer>
}

