import {AxiosInstance} from "axios";
import {ProtectionDTO} from "../lib";


export class  ScrapeAPI{
    static getInstance: (api: AxiosInstance) => ScrapeAPI;

    constructor(private api?: AxiosInstance) {
    }

    async run(protectionId: number) {
        return this.api!.patch("/scraper/protection/" + protectionId)
    }

    async getMeta(url:string) {
        return (await  this.api!.put("meta-scrap",{url})).data
    }
}

ScrapeAPI.getInstance = (api: AxiosInstance) => {
    return new ScrapeAPI(api)
}
