/** @jsxRuntime classic */
/** @jsx jsx */
import {LoadingOutlined} from "@ant-design/icons";
import {css, jsx} from '@emotion/react';
import React, {Fragment, useEffect, useState} from "react";
import {AdWordsLogo} from "./AdWordsLogo";

function AdWordsLoadingContent({tip,subTip}:{tip?:any, subTip?:any}) {

    const [visible, setVisible] = useState(false);

    useEffect(() =>{
        setTimeout(()=>setVisible(true),5000)
    },[])

    return <div
        css={css`position: fixed;z-index:99;top:0;left:0;width:100vw;height:100vh; background-color:white;display: flex;flex-direction:column;gap:1em;font-size:20px;align-items: center;margin:auto;justify-content:center`}>
        <AdWordsLogo size={64}/>
        <div css={css`align-items:center`}>
            <div>{tip?tip:"Loading Account"}{" "}</div>
        </div>
        {visible && <div css={css`color:#aaa;font-size:10px`}>{subTip?subTip:"( Around 60sec )"}</div>}
        <br/>
    </div>;
}

export function AdWordsLoading({loading, children, tip, subTip}: { loading: boolean, children: any, tip?: any, subTip?:any }) {
    const antIcon = <LoadingOutlined style={{fontSize: 16}} spin/>;

    const [visible, setVisible] = useState(false)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setVisible(true)
            }, 500)
        }
    }, [loading])

    if (!loading) {
        return children
    } else if (visible) {
        return (
            <AdWordsLoadingContent tip={tip} subTip={subTip}/>
        )
    } else {
        return children
    }
}

export function AdWordsStrictLoading({loading, children, tip, subTip}: { loading: boolean, children: any, tip?: any, subTip?:any }) {
    const antIcon = <LoadingOutlined style={{fontSize: 16}} spin/>;

    const [visible, setVisible] = useState(false)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setVisible(true)
            }, 500)
        }
    }, [loading])

    if (!loading) {
        return children
    } else if (visible) {
        return (
            <AdWordsLoadingContent tip={tip} subTip={subTip}/>
        )
    } else {
        return <Fragment/>
    }
}
