import React, {Fragment, useContext, useEffect} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {AxiosContext} from "@front-component";

export function Checkout({priceId=undefined}){
    const {api} = useContext(AxiosContext)


    useEffect(() => {

        async function load() {
            const config = (await api.get("/checkout/config")).data
            const stripePromise = await loadStripe(config.publicKey);
            const session = await( api.get(`/checkout/session${priceId?'/'+priceId:''}`)).then((res) => res?.data)
            await stripePromise.redirectToCheckout({
                sessionId: session.sessionId
            })
        }
    load().then()
    },[])

    return <Fragment/>

}
