/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from "@emotion/styled";
import {Button, Card, Modal} from "antd";
import {FreeCompetitorCheckResultForProtection} from "./FreeCompetitorCheckResultForProtection";
import {Logo} from "@front-component";

const Style = styled.div`
display: flex;
gap: 4em;
align-items: center;
flex-direction: column;
padding-top: 3em;

`

export function FreeCompetitorCheckResultPage({protectionId}){
    return <Style>
        <Logo  css={css`zoom:1.6`}/>
        <Card css={css`width:1100px; margin: auto`}  ><FreeCompetitorCheckResultForProtection protectionId={protectionId}/></Card>
    </Style>
}
