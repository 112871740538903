import {SVGProps} from "react";

export function SecureIcon(props:SVGProps<any>) {
    return (
        <svg
            width={228}
            height={163}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M208.189 146.633c2 3.544.304 15.515.304 15.515s-11.104-4.713-13.103-8.256a7.39 7.39 0 01-.681-5.598 7.371 7.371 0 013.464-4.442 7.333 7.333 0 015.582-.688 7.357 7.357 0 014.434 3.469z"
                    fill="#F1F1F1"
                />
                <path
                    d="M208.654 162.132l-.236.05c-2.272-10.823-10.206-18.163-10.286-18.236l.162-.178c.081.073 8.072 7.462 10.36 18.364z"
                    fill="#fff"
                />
                <path
                    d="M226.358 150.669c-2.713 4.935-18.012 11.622-18.012 11.622s-2.522-16.541.19-21.475a10.178 10.178 0 016.084-4.875 10.155 10.155 0 017.741.866 10.201 10.201 0 014.862 6.101c.749 2.6.438 5.391-.865 7.761z"
                    fill="#F1F1F1"
                />
                <path
                    d="M208.481 162.468l-.23-.243c11.071-10.541 13.937-25.244 13.964-25.391l.328.062c-.028.148-2.911 14.954-14.062 25.572z"
                    fill="#fff"
                />
                <path
                    d="M53.603 113.296a20.886 20.886 0 01-5.189-.673l-.334-.093-.31-.155c-11.201-5.632-20.652-13.07-28.088-22.109A83.756 83.756 0 015.499 65.015 97.425 97.425 0 01.018 30.778c.005-.244.009-.433.009-.563 0-5.663 3.135-10.632 7.986-12.659 3.714-1.551 37.428-15.436 39.864-16.44 4.587-2.304 9.482-.38 10.265-.044 1.756.72 32.923 13.502 39.658 16.718 6.942 3.314 8.794 9.268 8.794 12.263 0 13.561-2.343 26.236-6.962 37.67a87.264 87.264 0 01-15.634 25.263c-12.762 14.4-25.528 19.505-25.65 19.55-1.526.527-3.132.784-4.745.76zm-3.002-7.456c1.107.249 3.655.622 5.316.014 2.11-.771 12.794-6.326 22.778-17.592C92.49 72.696 99.49 53.129 99.5 30.103c-.025-.466-.355-3.794-4.75-5.892-6.604-3.153-39-16.435-39.326-16.569l-.09-.038c-.678-.285-2.839-.886-4.328-.103l-.298.139c-.361.149-36.15 14.887-39.966 16.482C8.072 25.237 7.12 28 7.12 30.215c0 .161-.004.397-.01.701-.305 15.755 3.331 54.523 43.491 74.924z"
                    fill="#3F3D56"
                />
                <path
                    d="M49.36 4.35S13.248 19.222 9.379 20.838c-3.87 1.617-5.804 5.497-5.804 9.376 0 3.88-2.902 54.504 45.787 78.981 0 0 4.419 1.226 7.771 0 3.353-1.226 45.915-21.917 45.915-79.142 0 0 0-5.82-6.771-9.053-6.771-3.233-39.51-16.65-39.51-16.65s-4.02-1.778-7.405 0z"
                    fill="#2E89E2"
                />
                <path
                    opacity={0.1}
                    d="M53.23 15.99v79.336S16.794 77.741 17.117 31.508L53.23 15.989z"
                    fill="#000"
                />
                <path
                    d="M53.705 73.01L42.098 58.047l6.75-5.264 5.496 7.085 18.565-19.651 6.209 5.898L53.705 73.01z"
                    fill="#fff"
                />
                <path
                    d="M227.722 163H121.665a.28.28 0 010-.559h106.057a.279.279 0 01.196.477.279.279 0 01-.196.082z"
                    fill="#CACACA"
                />
                <path
                    d="M152.323 158.601h4.305l2.048-16.65h-6.354l.001 16.65z"
                    fill="#FFB8B8"
                />
                <path
                    d="M151.224 157.192l8.479-.001c1.433 0 2.807.571 3.82 1.587a5.423 5.423 0 011.583 3.831v.176h-13.881l-.001-5.593z"
                    fill="#2F2E41"
                />
                <path
                    d="M166.798 158.601h4.305l2.048-16.65h-6.354l.001 16.65z"
                    fill="#FFB8B8"
                />
                <path
                    d="M165.699 157.192l8.479-.001c1.433 0 2.807.571 3.821 1.587a5.426 5.426 0 011.582 3.831v.176H165.7l-.001-5.593zM159.089 100.13l1.033 52.821-8.354-.519-5.796-33.645-1.83-22.94 14.947 4.283z"
                    fill="#2F2E41"
                />
                <path
                    d="M173.426 91.26l1.171 62.53-9.743-.28-2.104-37.169-3.661-13.457-14.947-7.035 2.44-16.822 21.964-.306 4.88 12.54z"
                    fill="#2F2E41"
                />
                <path
                    d="M156.629 35.288c4.126 0 7.471-3.353 7.471-7.49 0-4.138-3.345-7.491-7.471-7.491-4.126 0-7.471 3.353-7.471 7.49 0 4.137 3.345 7.491 7.471 7.491z"
                    fill="#FFB8B8"
                />
                <path
                    d="M162.825 38.444l1.145 1.739 3.05 8.564-2.44 33.338-14.337.306-1.83-38.233 3.834-4.805 10.578-.909z"
                    fill="#CCC"
                />
                <path
                    d="M142.617 45.075l-2.44-.306s-.611.306-.916 2.447c-.305 2.141-3.965 21.105-3.965 21.105l4.881 23.245 5.49-7.34-3.355-12.847 3.355-12.846-3.05-13.458zM173.731 44.77h1.83l4.576 24.774-4.271 19.269-4.27-7.035 1.22-7.646-.61-6.729-2.135-3.976 3.66-18.657zM161.043 27.798l-1.358-.34s-1.019-5.79-3.396-5.108c-2.377.68-8.489 1.362-8.489-1.362s5.772-5.107 9.168-4.767c3.396.34 7.733 1.47 8.829 6.47 1.758 8.013-3.626 10.038-3.626 10.038l.09-.292a4.556 4.556 0 00-1.218-4.64z"
                    fill="#2F2E41"
                />
                <path
                    d="M140.481 44.77l9.762-3.976 2.288-1.682 6.863 30.433 3.203-31.35 12.659 6.575-4.27 20.799-.61 7.952 1.83 6.423s6.406 4.588 4.27 9.482c-2.135 4.894-4.575 5.2-4.575 5.2s-10.372-9.788-10.982-12.235c-.61-2.447-1.525-6.729-1.525-6.729s-5.186 19.575-11.286 19.27c-6.101-.307-6.101-6.73-6.101-6.73l1.525-6.729 2.44-7.034-1.22-11.623-4.271-18.046z"
                    fill="#2F2E41"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0h228v163H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}