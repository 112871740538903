export type Config = {
    name: string,
    frontBaseUrl: string,
    partnerFrontBaseUrl: string,
}
export const Configs = [
    {
        name: "default",
        frontBaseUrl: "http://localhost:5173",
        partnerFrontBaseUrl: "http://localhost:5174",
    },
    {
        name: "production",
        frontBaseUrl: "https://app.getrank.io",
        partnerFrontBaseUrl: "https://partner.getrank.io",
    }
]