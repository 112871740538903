/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from "@emotion/styled";
import {Logo} from "@front-component";
import {Referral} from "./Referral";

const Style = styled.div`
display: flex;
gap: 4em;
align-items: center;
flex-direction: column;
padding-top: 3em;

`

export function FreeCreditUsedPage({protectionId}) {
    return <Style>
        <Logo css={css`zoom:1.6`}/>
        <div
            css={css`text-align: center; width: 100%;`}>
            <h1>Free credit already used</h1>
            <div className={"ant-form-item-explain"}>
                <b>Invite your friends</b> to get new credits, they will get free credits too!
            </div>
            <br/>
            <Referral protectionId={protectionId}/>
        </div>
    </Style>
}
