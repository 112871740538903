import {notification} from 'antd';
import React from 'react';

export function handleError(formName: string, e) {
    if (e?.response?.status === 401) {
        return;
    }
    if (e?.response?.status === 406) {
        return;
    }
    if (e?.response?.status === 402) {
        return;
    }
    notification.error({
        message: "Unknown error",
        description: "Unknown error",
    });
}
