import {SVGProps} from "react";

export function ReferralImage(props:SVGProps<any>) {
    return (
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width={"100%"}
            height={"100%"}
            viewBox={"0 0 810 710"}
            {...props}
        >
            <path
                data-name="Path 339"
                d="M810.572 168.892h-3.9V61.915A61.915 61.915 0 00744.757 0h-226.65a61.915 61.915 0 00-61.916 61.914v586.884a61.915 61.915 0 0061.915 61.915h226.648a61.915 61.915 0 0061.915-61.915V245.04h3.9z"
                fill="#3f3d56"
            />
            <path
                data-name="Path 340"
                d="M794.045 57.768v595.175a46.959 46.959 0 01-46.942 46.952h-231.3a46.966 46.966 0 01-46.973-46.952V57.768a46.965 46.965 0 0146.971-46.951h28.058a22.329 22.329 0 0020.656 30.74h131.868a22.329 22.329 0 0020.656-30.74h30.055a46.959 46.959 0 0146.951 46.942z"
                fill="#fff"
            />
            <path
                data-name="Path 45"
                d="M633.918 295.875a99.3 99.3 0 01-99.337-99.254v-.088c0-.208 0-.427.012-.635.3-54.4 44.863-98.7 99.325-98.7a99.337 99.337 0 01.011 198.674h-.011zm0-196.587a97.454 97.454 0 00-97.233 96.677c-.011.222-.011.4-.011.569a97.258 97.258 0 1097.27-97.246h-.026z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 39"
                d="M736.639 400.316H531.102a3.81 3.81 0 01-3.806-3.806v-50.984a3.811 3.811 0 013.806-3.806h205.537a3.811 3.811 0 013.806 3.806v50.985a3.811 3.811 0 01-3.806 3.806zm-205.537-57.074a2.286 2.286 0 00-2.284 2.284v50.985a2.286 2.286 0 002.284 2.284h205.537a2.286 2.286 0 002.284-2.284v-50.985a2.286 2.286 0 00-2.284-2.284z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 40"
                d="M594.666 360.361a2.664 2.664 0 000 5.329h125.605a2.665 2.665 0 00.204-5.326q-.058-.002-.117-.002H594.666z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 41"
                d="M594.666 376.347a2.664 2.664 0 000 5.329h125.605a2.665 2.665 0 00.204-5.326q-.058-.002-.117-.002H594.666z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 42"
                d="M736.639 485.576H531.102a3.81 3.81 0 01-3.806-3.806v-50.984a3.811 3.811 0 013.806-3.806h205.537a3.811 3.811 0 013.806 3.806v50.985a3.811 3.811 0 01-3.806 3.805zm-205.537-57.074a2.286 2.286 0 00-2.284 2.284v50.985a2.286 2.286 0 002.284 2.284h205.537a2.286 2.286 0 002.284-2.284v-50.985a2.286 2.286 0 00-2.284-2.284z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 43"
                d="M594.666 445.617a2.664 2.664 0 000 5.329h125.605a2.665 2.665 0 00.087-5.328H594.666z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 44"
                d="M594.666 461.607a2.664 2.664 0 000 5.329h125.605a2.665 2.665 0 00.087-5.328H594.666z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 39-2"
                d="M736.639 570.834H531.102a3.81 3.81 0 01-3.806-3.806v-50.984a3.811 3.811 0 013.806-3.806h205.537a3.811 3.811 0 013.806 3.806v50.985a3.811 3.811 0 01-3.806 3.806zM531.102 513.76a2.286 2.286 0 00-2.284 2.284v50.985a2.286 2.286 0 002.284 2.284h205.537a2.286 2.286 0 002.284-2.284v-50.985a2.286 2.286 0 00-2.284-2.284z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 40-2"
                d="M594.666 530.878a2.664 2.664 0 000 5.329h125.605a2.665 2.665 0 00.204-5.326q-.058-.002-.117-.002H594.666z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 41-2"
                d="M594.666 546.865a2.664 2.664 0 000 5.329h125.605a2.665 2.665 0 00.204-5.326q-.058-.002-.117-.002H594.666z"
                fill="#e6e6e6"
            />
            <path
                data-name="Path 1221"
                d="M665.307 194.123h-28.97v-28.971h-4.829v28.971h-28.97v4.828h28.97v28.971h4.829v-28.971h28.97z"
                fill="#2E89E2"
            />
            <g data-name="Group 76">
                <path
                    data-name="Path 1236"
                    d="M220.424 558.435s-23.688 25.944-27.072 85.727-4.512 66.551-4.512 66.551h84.6l15.784-40.603 18.052 40.6h80.087s9.024-124.079 0-127.463-166.939-24.812-166.939-24.812z"
                    fill="#2f2e41"
                />
                <circle
                    data-name="Ellipse 232"
                    cx={333.784}
                    cy={212.706}
                    r={40.607}
                    fill="#ffb8b8"
                />
                <path
                    data-name="Path 1238"
                    d="M509.187 559.567l-62.039-112.8-36.1-58.655 42.867-19.177 23.688 46.248s31.584 46.247 39.479 84.6 15.792 56.4 15.792 56.4z"
                    fill="#ffb8b8"
                />
                <path
                    data-name="Path 1239"
                    d="M167.401 526.851l92.495-81.215-4.507-65.424h-25.944l-7.9 33.84-74.447 98.135z"
                    fill="#ffb8b8"
                />
                <path
                    data-name="Path 1240"
                    d="M286.972 273.056s33.5 14.044 58.484-3.13c0 0 61.082 9.9 64.466 13.282s-3.384 104.9-3.384 104.9l-13.534 57.53s19.176 87.983-9.024 110.543l5.64 30.456s-125.206 9.024-174.838-21.432l9.022-30.458 23.688-95.879-14.664-133.1s11.276-30.456 54.144-32.712z"
                    fill="#e4e4e4"
                />
                <path
                    data-name="Path 1241"
                    d="M381.159 288.282l29.328-4.508s14.664 2.256 28.2 30.456 28.2 65.423 28.2 65.423l-50.759 22.56-41.735-65.423z"
                    fill="#e4e4e4"
                />
                <path
                    data-name="Path 1242"
                    d="M239.598 300.127l-6.768 5.64s-9.024 32.712-9.024 55.271v30.456l40.608 4.512z"
                    fill="#e4e4e4"
                />
                <path
                    data-name="Path 1243"
                    d="M293.188 223.727s-5.52-5.961-4.961-13.423a42.546 42.546 0 00-.805-12.685s1.728-12.213 4.279-19.293.4-9.543 11.366-13.408 3.985-17.841 36.314-6.7a8.844 8.844 0 009.619 1.968c5.969-2.186 9.566 6.014 9.566 6.014s3.723-3.055 5.812.121 12.193-4.062 15.9 19.041-9.014 43.706-9.014 43.706-1.785-36.97-22.781-37.641-44.74-8.623-47.685 6.945-7.61 25.355-7.61 25.355z"
                    fill="#2f2e41"
                />
                <circle cx={152.605} cy={526.628} r={17.5} fill="#ffb8b8" />
            </g>
            <path
                data-name="Path 1259"
                d="M26.445 644.824l288.216-57.532-24.086-120.66L2.359 524.161z"
                fill="#fff"
            />
            <path
                data-name="Path 1260"
                d="M317.02 588.868l-292.148 58.32L0 522.588l292.148-58.317zm-289 53.6l284.284-56.747-23.303-116.733L4.718 525.737z"
                fill="#e4e4e4"
            />
            <g data-name="Group 77">
                <path
                    data-name="Path 1230"
                    d="M120.418 606.472l-.118.05c-.94.387-1.892.753-2.87 1.074-.33.111-.67.215-1.001.314s-.672.206-1.014.299c-.557.16-1.127.302-1.7.434-.245.062-.503.117-.75.17l-.15.031c-.306.066-.614.131-.923.187l-.8.139a6.47 6.47 0 01-.44.073 42.558 42.558 0 116.612-82.5 42.556 42.556 0 0015.32 72.035 42.363 42.363 0 01-12.163 7.698z"
                    fill="#2E89E2"
                />
                <path
                    data-name="Path 1231"
                    d="M181.26 584.828a41.567 41.567 0 005.767 2.368 42.333 42.333 0 01-19.62 10.036l-.535.114a42.559 42.559 0 114.835-82.183 42.477 42.477 0 009.554 69.665z"
                    fill="#2E89E2"
                />
                <path
                    data-name="Path 1232"
                    d="M254.612 535.18a42.558 42.558 0 11-50.48-32.774 42.558 42.558 0 0150.48 32.774z"
                    fill="#2E89E2"
                />
                <path
                    data-name="Path 1233"
                    d="M236.79 573.156a28.515 28.515 0 01.628 5.73 42.639 42.639 0 01-32.567 6.926 28.52 28.52 0 01-1.758-5.49c-2.083-9.79 1.145-19.128 7.423-23.352a13.172 13.172 0 119.99-2.124c7.456 1.303 14.202 8.518 16.284 18.31z"
                    fill="#fff"
                />
                <path
                    data-name="Path 1234"
                    d="M181.262 584.83l.027.13a43.496 43.496 0 01.485 7.149c-4.409 3.092-9.336 3.985-14.9 5.24l-.536.114a42.388 42.388 0 01-16.988.153 4.412 4.412 0 01-.198-.496 27.278 27.278 0 01-1.56-4.994c-2.082-9.791 1.146-19.128 7.424-23.352a13.17 13.17 0 01-.271-25.82 12.72 12.72 0 013.07-.28 42.51 42.51 0 0023.448 42.159z"
                    fill="#fff"
                />
                <path
                    data-name="Path 1235"
                    d="M127.871 596.92a27.329 27.329 0 01.526 5.151 42.406 42.406 0 01-10.966 5.523c-.33.111-.67.215-1 .315s-.672.205-1.014.298c-.557.16-1.127.302-1.7.434-.117.036-.245.063-.365.088l-.535.114c-.307.065-.614.13-.923.186l-.8.14a6.468 6.468 0 01-.44.073 42.181 42.181 0 01-14.824-.245.172.172 0 01-.025-.068 3.299 3.299 0 01-.174-.429 27.274 27.274 0 01-1.559-4.993c-2.082-9.792 1.146-19.128 7.423-23.352a13.17 13.17 0 01-.27-25.82 12.897 12.897 0 012.168-.276 42.513 42.513 0 0024.48 42.86z"
                    fill="#fff"
                />
            </g>
            <path
                data-name="Path 1265"
                d="M566.359 378.666a14.258 14.258 0 01-.289 2.868 21.322 21.322 0 01-16.65 0 14.263 14.263 0 01-.289-2.868c0-5.006 2.549-9.237 6.06-10.651a6.587 6.587 0 115.107 0c3.512 1.414 6.061 5.645 6.061 10.651z"
                fill="#2E89E2"
            />
            <path
                data-name="Path 1266"
                d="M566.359 465.653a14.259 14.259 0 01-.289 2.868 21.322 21.322 0 01-16.65 0 14.264 14.264 0 01-.289-2.868c0-5.006 2.549-9.237 6.06-10.651a6.587 6.587 0 115.107 0c3.512 1.414 6.061 5.645 6.061 10.651z"
                fill="#2E89E2"
            />
            <path
                data-name="Path 1267"
                d="M566.359 550.91a14.259 14.259 0 01-.289 2.868 21.322 21.322 0 01-16.65 0 14.264 14.264 0 01-.289-2.868c0-5.006 2.549-9.237 6.06-10.651a6.587 6.587 0 115.107 0c3.512 1.414 6.061 5.645 6.061 10.651z"
                fill="#2E89E2"
            />
            <circle cx={522.105} cy={564.128} r={16} fill="#ffb8b8" />
        </svg>
    )
}
