import "./Placeholders.css";

import React, {Fragment} from "react";
import styled from "@emotion/styled";
import {Spin} from "antd";
import Lottie from "lottie-react-web";
import loadingData from "../../assets/loading.json";
import {LoadingOutlined} from '@ant-design/icons';
import {AdWordsLoading} from "../../assets";

export function Line({motion = true}: { motion?: boolean }) {
    return (
        <div className={`placeholder${motion ? "" : " motion-less"}`}>
            <div className="placeholder__line"/>
        </div>
    );
}

export function Graph({motion = true}: { motion?: boolean }) {
    return (
        <svg
            className={`placeholder__svg${motion ? "" : " motion-less"}`}
            width="86"
            height="60"
            viewBox="0 0 86 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                // id="Vector 5"
                d="M0 44L22.3306 19.6017C25.1403 16.5319 29.5574 15.523 33.4213 17.0685L47.7955 22.8182C51.798 24.4192 56.3759 23.2751 59.1546 19.9794L76 0"
                transform="translate(5 8)"
                // stroke="#F2F2F2"
                strokeWidth="10"
                strokeLinecap="round"
            />
        </svg>
    );
}

export function LineGraphPlaceholder() {
    return (
        <div className="placeholder placeholder__line-graph">
            <div className="placeholder__line"/>
            <div className="placeholder__graph">
                <Graph/>
            </div>
        </div>
    );
}

export function StatsPlaceholder({motion = true}: { motion?: boolean }) {
    return (
        <div className={`placeholder${motion ? "" : " motion-less"}`}>
            <div className="placeholder__group">
                <div className="placeholder__line"/>
                <div className="placeholder__sbox"/>
            </div>
            <Graph motion={motion}/>
        </div>
    );
}

export function RedirectPlaceholder({motion = true}: { motion?: boolean }) {
    return (
        <div
            className={`redirect-placeholder placeholder${
                motion ? "" : " motion-less"
            }`}
        >
            <div className="redirect__line">
                <div>
                    <div className="redirect-placeholder__square"/>
                    <div className="redirect-placeholder__line--large"/>
                </div>
                <div className="redirect-placeholder__line--small"/>
            </div>
            <div className="redirect__line">
                <div className="redirect-placeholder__line--large"/>
                <div className="redirect-placeholder__line--xsmall"/>
            </div>
            <div className="redirect__line">
                <div>
                    <div className="redirect-placeholder__line--xxxsmall"/>
                    <div className="redirect-placeholder__circle"/>
                    <div className="redirect-placeholder__circle"/>
                    <div className="redirect-placeholder__circle"/>
                </div>
                <div className="redirect-placeholder__line--xxsmall"/>
            </div>
        </div>
    );
}

export function SmallPlaceholder({
                                     title,
                                     text,
                                     loading,
                                     children
                                 }: { title?: string, text?: string, loading?: boolean, children?: any }) {
    if (loading === undefined) {
        loading = true;
    }
    return (
        <Fragment>
            {loading && (
                <div
                    style={{
                        height: "100%",
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "25%",
                        paddingBottom: "25%"
                    }}
                >
                    <h2>{title || "Work in progress..."}</h2>
                    <div>{text}</div>
                    <br/>
                    <AdWordsLoading loading={loading}>
                        <Fragment></Fragment>
                    </AdWordsLoading>
                </div>
            )}
            {!loading && children}
        </Fragment>
    );
}

const Style = styled.div`
  .bp3-spinner-animation svg {
    height: 25px !important;
    width: 25px !important;
  }
`;


/**
 * SmallInlinePlaceholder
 * @param title
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
export function SmallCompactPlaceholder({text}: any) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
        <Style
            style={{
                height: "100%",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                padding: "1em"
            }}
        >
            <div>{text}</div>
            <Spin indicator={antIcon}/>
        </Style>
    );
}

export function SmallLoader({text}: any) {
    const antIcon = <LoadingOutlined  spin />;

    return ( <Spin indicator={antIcon}/>);
}
/**
 * GlobalPlaceholder
 * @param loadingText
 * @returns {JSX.Element}
 * @constructor
 */
export function GlobalPlaceholder({
                                      loadingText = "Loading..."
                                  }: {
    loadingText: string
}) {
    return (
        <div className="container">
            <div className="loading">
                <div className="loading__text">{loadingText}</div>
            </div>
        </div>
    );
}

export const PlaceholderText = styled.span`
  background-color: #f2f2f2;
  height: 1em;
  border-radius: 20px;
  display: inline-block;
`;
