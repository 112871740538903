/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from "@emotion/styled";
import {Button, Form, Input, Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {AdWordsStrictLoading, AxiosContext, AxiosContextType, identify} from "@front-component";
import {useLoading} from "@front-component";
import axios from "axios";
import {getFlag} from "../../../../../front-component/src/utils";

const Style = styled.div`
text-align: center;

.ant-form{
width: 480px;
margin: auto;
}
`

export function FreeCompetitorCheck() {
    const [form] = Form.useForm();
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [geoCriteria, setGeoCriteria] = useState([])
    const {doAction, loading} = useLoading(true)

    async function getAvailableGeoTargets() {
        let availableGeoTargets = (await api.get("/geo-target")).data
        availableGeoTargets = availableGeoTargets.map(g => {
            if (g.countryCode === "US") {
                g.active = true
            }
            return g
        })
        setGeoCriteria(availableGeoTargets);
    }


    async function checkCompetitors(values) {
        try {

            identify(undefined, values)
            const protectionData = await api.patch("/public/free-scrap/" + values.brandName, {
                email: values.email,
                geoCriteria: values.countries.map(countryName => geoCriteria.find(geoCriterion => geoCriterion.name === countryName))
            })
            window.location.href = "/free-competitor-check/in-progress/" + protectionData.data.id
            //window.location.href = "https://www.getrank.io/?confirm=true"
        } catch (e) {
            if (e.response?.status === 400) {
                const protectionData = await api.get("/public/protection-for-email/" + values.email)
                window.location.href = "/free-credit-used/" + protectionData.data?.id
            } else {
                throw e
            }
        }
    }

    useEffect(() => {doAction(async () => {
        await getAvailableGeoTargets()
    })}, [])

    return <AdWordsStrictLoading loading={loading}>
        <Style>
            <Form onFinish={checkCompetitors} form={form}
                  layout={"vertical"}>
                <h1>Free competitor ad check</h1>
                <p>Check if a <b>competitor is bidding on your brand name</b> on Adwords, everywhere in the world. </p>
                <Form.Item
                    name="brandName"
                    label="Your brand name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your brand name',
                        },
                    ]}
                >
                    <Input placeholder="apple"/>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Your professional email address"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please input your professional email address!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || value?.toLowerCase()?.includes(getFieldValue('brandName')?.toLowerCase()?.replaceAll(" ", ""))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Email address doesn’t match brand name'));
                            },
                        }),
                    ]}
                >
                    <Input placeholder="tim@apple.com"/>
                </Form.Item>
                <Form.Item label={"Countries"}
                           name="countries"
                           initialValue={[geoCriteria.find(g => g.active)?.name]}
                           tooltip={"Countries that are going to be monitor by GetRank."}
                           rules={[
                               {
                                   required: true,
                                   message: 'Please input the countries to check!',
                               },
                               ({getFieldValue}) => ({
                                   validator(_, value) {
                                       if (value && value.length > 4) {
                                           return Promise.reject(new Error('Free trial is limited to 4 countries maximum'));
                                       }
                                       return Promise.resolve();
                                   },
                               }),
                           ]}>
                    <Select
                        css={css`min-width:100px`}
                        mode={"multiple"}
                        allowClear={true}
                        maxTagCount={6}
                        placeholder={geoCriteria.length === 0 ? "No country specified" : "Select countries"}>
                        {geoCriteria.map(geo => (
                            <Select.Option selected={geo.active}
                                           value={geo.name}>
                                <div css={css`display: flex;gap:1em;`}>
                                    <span>{(getFlag(geo.countryCode) as any)?.emoji}</span>
                                    <span>{geo.canonicalName}</span>
                                </div>
                            </Select.Option>))}
                    </Select>
                </Form.Item>
                <Form.Item
                    help={<div css={css`margin-top: 1em`}><p>By sign up, you agree to our <a
                        href={"https://getrank.io"}>Terms
                        of Service</a> and our <a
                        href={"https://getrank.io"}>Privacy Policy</a>.</p></div>}>
                    <Button css={css`width:100%`} size={"large"} type={"primary"} htmlType="submit">Check my brand
                        now</Button>
                </Form.Item>
            </Form>
        </Style>
    </AdWordsStrictLoading>
}
