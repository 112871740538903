import {Button, Form, Input, message} from "antd";
import {UserAPI} from "@backend-lib";
import axios from "axios";

export function ChangePassword({user}) {
    const onFinish = async (values) => {
        try {
            await UserAPI.changePassword(axios, {newPassword: values.newPassword})
            message.success('Password changed successfully');
        } catch (error) {
            console.error('Error:', error);
            message.error('An error occurred');
        }
    };

    return (
        <Form onFinish={onFinish}>
            <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                    {required: true, message: 'Please enter your new password'},
                    {min: 6, message: 'Password must be at least 6 characters long'},
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['newPassword']}
                rules={[
                    {required: true, message: 'Please confirm your new password'},
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Passwords do not match');
                        },
                    }),
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Change Password
                </Button>
            </Form.Item>
        </Form>
    );
}