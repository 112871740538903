import React, {Fragment, useCallback, useMemo, useState} from "react";
import {SmallCompactPlaceholder, SmallLoader, SmallPlaceholder} from "../components/placeholders/Placeholders"


/**
 * Hook that allow to manage loading
 * @param defaultLoad
 * @returns {{ExclusiveLoadingContainer: (function(*): *), ExclusiveInlineLoadingContainer: (function(*): *), doAction: (function(*): *), loading: boolean}}
 */
export function useLoading(defaultLoad?: boolean) {
    const [loading, setLoading] = useState(defaultLoad);

    const doAction = useCallback((action:any) => {
        setLoading(true);
        if (!action) {
            console.error("Missing action, you might forgot async");
        }
        return action()
            .catch((e:Error) => {
                console.error(e);
                throw e;
            })
            .finally(() => setLoading(false));
    }, []);

    const ExclusiveLoadingContainer = useMemo(
        () => (props: any) => {
            if (loading) {
                return <SmallPlaceholder text={"Loading"}/>;
            }
            return <Fragment>{props.children}</Fragment>;
        },
        [loading]
    );

    const CompactLoader = useMemo(
        () => (props: any) => {
            if (loading) {
                return <span style={{position:"absolute",  padding:0, margin:0, marginLeft:20 }}><SmallLoader/></span>;
            }
            return <Fragment>{props.children}</Fragment>;
        },
        [loading]
    );

    const ExclusiveInlineLoadingContainer = useMemo(
        () => (props: any) => {
            if (loading) {
                return <SmallCompactPlaceholder text={""}/>;
            }
            return <Fragment>{props.children}</Fragment>;
        },
        [loading]
    );
    return {
        doAction,
        ExclusiveLoadingContainer,
        ExclusiveInlineLoadingContainer,
        CompactLoader,
        loading,
    };
}
