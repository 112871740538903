import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .ant-progress{
    margin: 0;
  }
  `
