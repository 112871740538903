import {notification} from 'antd';
import {useContext} from 'react';
import {useDeepCompareCallback} from "use-deep-compare";
import {AxiosContext, AxiosContextType, UserContextType} from '../context';

export function useUser(userContext: UserContextType) {
    const unloadMe = async () => {
        await userContext.setUser(undefined)
        await api!.delete('/login');
    };

    const {api: api} = useContext<AxiosContextType>(AxiosContext);

    const cookie = async () => {
        await api!.get('/cookie');
    };

    const loadMe = useDeepCompareCallback(async (token?: string) => {
        try {
            let config = {};
            if (token) {
                config = {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            }
            const res = await api!.get('/auth/me', config);
            if (res?.data) {
                await userContext.setUser(res.data);
            }
        } catch (error) {
            if (window.location.pathname !== '/login') {
                // navigate("/login")s
                return Promise.reject(error);
            }
        }
        return undefined;
    }, [userContext]);

    const loginBo = async (email: string, password: string, remember: string) => {
        try {
            localStorage.removeItem('CandidateFileContext');
            const resLogin = await api!.post('/auth/login-bo', {
                username: email,
                password: password,
                remember,
            }, {});
            if (resLogin?.status < 300) {
                const accessToken = resLogin.data.access_token;
                let config = {};
                if (accessToken) {
                    config = {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    };
                }
                const res = await api!.get('/auth/me', config);
                if (res?.data) {
                    await userContext.setUser(res.data);
                }
            }
        } catch (e: any) {
            if (e?.response?.status === 401) {
                notification.error({
                    message: "login.invalid",
                    description: "login.invalid",
                });
            } else {
                notification.error({
                    message: "login.invalid",
                    description: "login.invalid",
                });
            }
            throw e;
        }
        return undefined;
    };
    const login = async (email: string, password: string, remember: string) => {
        try {
            localStorage.removeItem('CandidateFileContext');
            const res = await api!.post('/auth/login-partner', {
                username: email,
                password: password,
                remember,
            }, {});
            if (res?.status < 300) {
                const accessToken = res.data.access_token;
                await loadMe(accessToken);
                return res.data.payload;
            }
        } catch (e: any) {
            if (e?.response?.status === 401) {
                notification.error({
                    message: "login.invalid",
                    description: "login.invalid",
                });
            } else {
                notification.error({
                    message: "login.invalid",
                    description: "login.invalid",
                });
            }
            throw e;
        }
        return undefined;
    };

    return {
        cookie,
        unloadMe,
        loadMe,
        login,
        loginBo,
    };
}
