/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Empty, Form, Select} from "antd";
import FormItem from "antd/lib/form/FormItem";
import {useContext, useEffect, useState} from "react";
import {AdWordsLoading, AxiosContext, AxiosContextType, track, useLoading} from "@front-component";
import {getGeoCriteria} from "../../utils/campaigns";
import {GoogleAPI} from "@backend-lib";

const Style = styled.div``

export function GoogleCampaigns({onSelect, customerId}: { onSelect: Function, customerId?: number }) {
    console.log("redraw GoogleCampaigns")
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [campaigns, setCampaigns] = useState([])
    const {doAction:doLoadCampaigns, loading:loadingCampaigns} = useLoading(true)
    const {doAction:doSaveCampaigns, loading:loadingSaveCampaigns} = useLoading(false)

    const fetchCampaignsNamesFromGoogle = (async () => {
        const googleCampaignsData = await new GoogleAPI(api).getCampaignsNames(customerId)
        setCampaigns(googleCampaignsData)
        return googleCampaignsData
    })

    const fetchCampaignFromGoogle = async (campaignId:number) => doSaveCampaigns(async () => {
        return  await new GoogleAPI(api).getCampaign(campaignId)
    })

    const load = () => doLoadCampaigns(async () => {
        setCampaigns(await fetchCampaignsNamesFromGoogle())
    })

    useEffect(() => {
        load().then()
    }, [customerId])

    async function onCampaignChange(campaignId:number) {
        if (!campaignId) {
            return
        }
        const selectedCampaign = await fetchCampaignFromGoogle(campaignId)
        if (selectedCampaign) {
            const geoTargets = getGeoCriteria(selectedCampaign).filter(g => g !== undefined)
            if (geoTargets.length === 0) {
                /* notification.warn({
                     message: "Invalid Ads Campaign",
                     description: <Fragment>Please at least one country in your campaign, <a href={"google.fr"}>How to do
                         it?</a></Fragment>,
                 });*/
            }
            await onSelect(selectedCampaign)
            track("campaign-selected", selectedCampaign)

        }
    }

    return (
        <AdWordsLoading loading={loadingSaveCampaigns} tip={"Loading Google Campaign details"} subTip={""}>
            <AdWordsLoading loading={loadingCampaigns} tip={"Loading Google Campaigns"} subTip={""}>
                <Style>
                    <div css={css``}>
                        {campaigns.length == 0 && <Empty/>}
                        {campaigns.length > 0 &&
                        <Form layout={"vertical"}>
                            <FormItem name={"campaign"} label={""}
                                      rules={[{required: true, type: 'number'}]}>
                                <Select placeholder={"All campaigns"} css={css`min-width: 300px`}
                                        onChange={onCampaignChange}>
                                    {campaigns.sort((c1:any, c2:any) => c1.googleAdsCampaign.campaign.status - c2.googleAdsCampaign.campaign.status)
                                        .map((c:any) => (
                                            <Select.Option
                                                disabled={c.googleAdsCampaign.campaign.status === 4 }
                                                value={c.googleAdsCampaign.campaign.id}>
                                                <div
                                                    css={css`color:${c.googleAdsCampaign.campaign.status === 1 ? "#1890ff" : (c.googleAdsCampaign.campaign.status === 2 ? "#1890ff" : "auto")}`}>
                                                    {c.googleAdsCampaign.campaign.name + " (" + c.googleAdsCampaign.campaign.id + ")"}
                                                </div>
                                            </Select.Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </Form>
                        }
                    </div>
                </Style>
            </AdWordsLoading>
        </AdWordsLoading>
    )
}
