/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {
    CreditCardOutlined,
    DownOutlined,
    FilePdfOutlined,
    SafetyCertificateOutlined,
    SettingOutlined
} from "@ant-design/icons"
import {Dropdown, Layout, Menu, Tag} from "antd";
import {Content} from "antd/lib/layout/layout";
import {PageHeader} from '@ant-design/pro-layout';
// @ts-ignore
import React, {Fragment, useContext, useEffect} from "react";
import {AxiosContext, AxiosContextType, Logo, UserContext} from "@front-component";
import styled from "@emotion/styled";

const Style = styled.div`
  .ant-page-header-heading-extra {
    display: flex !important;
    align-items: center !important;
  }
`
export function GooglePageContainer({children}: { children: any }) {
    console.log("redraw GooglePageContainer")
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {user, setUser} = useContext(UserContext)


    const menu = (<Fragment>
            {user && <Tag color="green">{"Google: " + user?.account?.selectedGoogleCustomer?.descriptiveName}</Tag>}
            {!user && <Tag color="orange">In progress</Tag>}
            <Menu>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="/logout">
                        Disconnect
                    </a>
                </Menu.Item>
            </Menu>
        </Fragment>
    );

    return <Fragment>
        <Style>
        <Layout>
            <PageHeader
                title=""
                className="site-page-header"
                subTitle=""
                childrenContentStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
                tags={<Fragment>
                    <div css={css`display:flex;align-items: center`}>
                        <Logo></Logo>
                        <Menu style={{minWidth:500}} mode="horizontal" selectedKeys={[window.location.pathname]}>
                            <Menu.Item icon={<SafetyCertificateOutlined/>} key={"/brand-protection/dashboard"}
                                       onClick={() => window.location.href = ("/brand-protection/dashboard")}
                            >Brand protect</Menu.Item>
                            <Menu.Item icon={<SettingOutlined/>} key={"/google/settings"}
                                       onClick={() => window.location.href = ("/google/settings")}>Settings</Menu.Item>
                            <Menu.Item icon={<CreditCardOutlined/>} key={"/billing"}
                                       onClick={() => window.location.href = ("/billing")}>Billing</Menu.Item>
                            <Menu.Item icon={<FilePdfOutlined/>} key={"/guide"}
                                       onClick={() => window.location.href = ("/guide")}>
                                Set Up guide</Menu.Item>
                        </Menu>
                    </div>
                </Fragment>}
                extra={[
                    <Dropdown overlay={menu} >
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {user?.email} <DownOutlined/>
                        </a>
                    </Dropdown>
                ]}>
            </PageHeader>
            <Layout>
                <Content style={{ padding: '0 50px' }}>
                        {children}
                </Content>
            </Layout>
        </Layout>
        </Style>
    </Fragment>
};
