/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Divider} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {AdWordsStrictLoading, UserContext} from "@front-component";
import {AxiosContext, AxiosContextType} from "@front-component";
import {useLoading} from "@front-component";
import {ProtectionAPI, ProtectionDTO, ScrapeAPI} from "@backend-lib";

const Style = styled.div``

export function MonitorThreats({selectedProtectionId}: any) {
    const {user, setUser} = useContext(UserContext)
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {doAction, loading} = useLoading()
    const [protection, setProtection] = useState(undefined)

    const load = () => doAction(async () => {
        let protection: ProtectionDTO
        if (selectedProtectionId) {
            protection = await new ProtectionAPI(api).getProtection(selectedProtectionId)
        } else {
            protection = (await new ProtectionAPI(api).getProtections())[0]
        }
        setProtection(protection)

        try {
            await new ScrapeAPI(api).run(protection?.id)
        } finally {
            await showThreats(protection.id)
        }
    })

    useEffect(() => {
        load().then()
    }, [])

    async function showThreats(protectionId) {
        window.location.href = "/brand-protection/threats/" + protectionId
    }

    return <AdWordsStrictLoading loading={loading} tip={<span>{"Looking for competitors in "}
        <span css={css`font-size:10px; text-align:center`}>{protection?.geoCriteria?.map(geo =>
            <div>{geo.name}</div>)}</span></span>} subTip={"( Can take more than 3 minutes )"}>
        <Divider/>
    </AdWordsStrictLoading>
}

