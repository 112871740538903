/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {DeleteOutlined} from "@ant-design/icons";
import {Button, notification, Popconfirm} from "antd";
import {Fragment, useContext, useEffect, useState} from "react";
import {AxiosContext, AxiosContextType, UserContext} from "@front-component";
import {GoogleCustomers} from "./GoogleCustomers";

export function GoogleSettings() {
    const { api } = useContext<AxiosContextType>(AxiosContext);
    const userCtx = useContext(UserContext)
    const {user, setUser} = userCtx

    const [customer, setCustomer] = useState(user?.account?.selectedGoogleCustomer)

    useEffect(() =>{setCustomer(user?.account?.selectedGoogleCustomer)},[user?.account?.selectedGoogleCustomer])
    const setSelectedGoogleCustomer = async (customer)=>{
        notification.success({
            message: "Account configured",
            description: <Fragment>{customer.descriptiveName || customer.id}</Fragment>,
        });
        const data = await api!.patch("/account/" + user?.account.id,{selectedGoogleCustomer: customer, googleCustomerId: customer.id})
        setCustomer(customer)
        //await loadMe();
        setTimeout(()=>{window.location.href ="/brand-protection/edit/"},1000)
    }

    const removeGoogleIntegration = async ()=>{
        await api!.patch("/account/" + user?.account.id,{selectedGoogleCustomer: null})
        await api!.patch("/account/" + user?.account.id,{selectedGoogleCampaigns: []})
        setCustomer(undefined)
    }

    if (!customer ) {
        return <GoogleCustomers onSelect={setSelectedGoogleCustomer}/>
    } else {
        return <Fragment>
                    <div css={css`
display:grid;
grid-template-areas: "name id action";
grid-template-rows: 50px 1fr;
align-items: center;
                       
`}>
                        <div css={css`area:name; color: #3F3F3F`}>
                            Account name
                        </div>
                        <div css={css`area:id; color: #3F3F3F`}>
                            ID
                        </div>
                        <div css={css`area:action`}>

                        </div>
                        <div css={css`area:name`}>
                            {customer.descriptiveName || customer.id}
                        </div>
                        <div css={css`area:id`}>
                            {customer.id}
                        </div>
                        <div css={css`area:action`}>
                            <Popconfirm title={"Are you sure to delete Google Integration?"} onConfirm={removeGoogleIntegration}>
                            <Button shape={"round"} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                        </div>

                    </div>
                    <br/>
        </Fragment>
    }

}
