/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import {useContext} from "react";
import {Card} from "antd";
import {ChangePassword} from "../components/settings/ChangePassword";
import {UserContext} from "@front-component";


const Style = styled.div``
export function SettingsChangePasswordPage(){
    const {user} = useContext<any>(UserContext)
    return <Style>
        <Card title={"Change Password"} bordered={false} >
            <ChangePassword user={user}/>
        </Card>
    </Style>
}