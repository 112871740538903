/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';

export function AdWordsLogo({size}: { size?: number }) {

    return (
        <div css={css`

svg path{
    fill-opacity: 0.2;
    -moz-animation:FillIn 2s ease-in-out 1s forwards;
    -webkit-animation:FillIn 2s ease-in-out 1s forwards;
    animation:FillIn 2s ease-in-out 1s forwards;
    animation-iteration-count:infinite;
  /* animation-delay: .5s; */
}

@keyframes FillIn {
  from {
    fill-opacity: 0.2;
  }
  to {
    fill-opacity: 1;
  }
}
`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                fill="none"
                viewBox="0 0 22 22"
            >
                <path
                    fill="#28B446"
                    d="M22 21.634h-5.6a2.063 2.063 0 01-1.947-1.379l-3.068-8.737L11 10.422l.116-5.353L14.53.365l1.013 2.884L22 21.634z"
                ></path>
                <path
                    fill="#219B38"
                    d="M15.544 3.25l-4.159 8.268L11 10.422l.116-5.353L14.53.365l1.013 2.884z"
                ></path>
                <path
                    fill="#518EF8"
                    d="M14.531.365L11 10.422l-3.453 9.833a2.062 2.062 0 01-1.947 1.38H0L7.468.364h7.063z"
                ></path>
            </svg>

        </div>
    );
}
