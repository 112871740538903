/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from '@emotion/react';
import {Result} from "antd";
// @ts-ignore
import {location, navigate} from "hookrouter";
import qs from "query-string";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AdWordsLoading, AxiosContext, AxiosContextType, track} from "@front-component";

export function GoogleOAuthCallback() {
    const { api: api } = useContext<AxiosContextType>(AxiosContext);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)

    async function load() {
        if (window.location?.search) {
            setLoading(true)
            try {
                const {code} = qs.parse(window.location?.search);
                await api!.get("/google-ads-api/oauth/callback?code=" + code)
                window.location.href = ("/google/settings")
                setError(undefined)
            } catch (e) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        load().then()
    }, [window.location?.search])

    if (error) {
        console.error(error)
        return        <AdWordsLoading loading={loading}><Result status={"error"}>Unable to connect to your Google Account</Result></AdWordsLoading>
    } else {
        return        <AdWordsLoading loading={loading}><Fragment/></AdWordsLoading>
    }
}
