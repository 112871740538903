/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {useDetectAdBlock} from "adblock-detect-react";
import {Alert, Button, Spin} from "antd";
import axios from "axios";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AdWordsLogo, AxiosContext} from "@front-component";

const Style = styled.div`
margin: auto;
width:fit-content;
`;

export function GoogleLogin() {
    const {api} = useContext(AxiosContext)
    const [url, setUrl] = useState(undefined)
    const adBlockDetected = useDetectAdBlock();

    async function load() {
        if (!url) {
            const data = await api!.get("/google-ads-api/oauth/redirect-url")
            setUrl(data.data.redirectUrl)
        }
    }

    useEffect(() => {
        load().then()
    }, [])

    if (adBlockDetected && !url) {
        return <Alert message={"We are not able to connect to your Google Ads Account if you are using AdBlock,"}
                      type={"error"}
                      description={<Fragment><a
                          href={"https://help.getadblock.com/support/solutions/articles/6000055743-how-to-disable-adblock-on-specific-sites"}
                          target={"_blank"}>
                          How do I turn off my Ad Blocker?
                      </a></Fragment>}/>
    }
    if (!url) {
        return <Spin tip={"Waiting for Google"}></Spin>
    }
    return (
        <Fragment>
            <Style>
                <a
                    href={url}
                >
                    <Button css={css`
  height:51px;
  padding: 1em;
  display: flex;
  text-decoration: none !important;                  
                      
  gap: 1em;`}>
                        <div className="google-icon-wrapper">
                            <AdWordsLogo size={22}/>
                        </div>
                        <b css={css`text-decoration: none !important;`}>Sign up with Adwords</b>
                    </Button>
                </a>
            </Style>
        </Fragment>
    )
}
