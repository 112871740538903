import {AxiosInstance} from "axios";
import {ProtectionDTO} from "../lib";


export class ProtectionAPI {
    static getInstance: (api: AxiosInstance) => ProtectionAPI;

    constructor(private api?: AxiosInstance) {
    }

    async getProtections() {
        const protections: ProtectionDTO[] = (await this.api!.get("/protection")).data
        return protections
    }

    async removeProtection(protectionId: number) {
        return this.api!.delete("/protection/" + protectionId)
    }

    async getProtection(selectedProtectionId: number) {
        const response = await this.api!.get("/protection/" + selectedProtectionId)
        return response.data
    }
}

ProtectionAPI.getInstance = (api: AxiosInstance) => {
    return new ProtectionAPI(api)
}
