/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx } from '@emotion/react';
import {Button, Card,  Divider} from "antd";
import React from "react";
import {BrandProtectionsList} from "@front-component";

const Style = styled.div``

export function BrandProtectionsListPage(){

    return <Style>
        <Card title={"Brand protection"} bordered={false} >
            <BrandProtectionsList/>
            <Divider/>
        </Card>
    </Style>
}

