/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from "@emotion/styled";
import {Card, Modal} from "antd";
import {FreeCompetitorCheck} from "./FreeCompetitorCheck";
import {Logo} from "@front-component";

const Style = styled.div`
display: flex;
gap: 4em;
align-items: center;
flex-direction: column;
padding-top: 3em;

`

export function FreeCompetitorCheckPage(){
    return <Style>
        <Logo css={css`zoom:1.6`}/>
        <Card css={css`max-width:800px; margin: auto`} ><FreeCompetitorCheck/></Card>
    </Style>
}
