export function getGeoCriteria(campaign){
    const r =  campaign?.googleAdsCampaign?.campaignCriteria?.map(crit=>crit.geoTarget).filter(geo=>geo).filter(geo=>geo.targetType==="Country") || []
    return r
}
export function getKeywordsCriteria(campaign){
    const r =  campaign?.googleAdsCampaign?.keywords?.map(crit=>crit.adGroupCriterion.criterion).filter(crit=>crit) || []
    return r
}
export function getBudget(campaign){
    return campaign?.googleAdsCampaign?.campaignBudget || {}
}

export function getLanguageCriteria(campaign){
    return campaign?.googleAdsCampaign?.campaignCriteria?.map(crit=>crit.languageConstant).filter(languageConstant=>languageConstant) || []
}
export function getDeviceCriteria(campaign){
    return campaign?.googleAdsCampaign?.campaignCriteria?.map(crit=>crit.device).filter(device=>device) || []
}


export function isKeywordsCriterion(a: any) {
    return !!a.criterion.text && !!a.criterion.matchType;
}

export function isGeoCriterion(a: any) {
    return !!a.criterion.countryCode && !!a.criterion.targetType;
}
