/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {CheckOutlined} from "@ant-design/icons";
import {Button, Card, Col, Divider, Row, Statistic, Timeline} from "antd";
import * as _ from "lodash";
import moment from 'moment';
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AdWordsLoading, AxiosContext, AxiosContextType, BrandProtectionThreatsRenderer} from "@front-component";



export function BrandProtectionThreatsPage({selectedProtectionId}) {


    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [stats, setStats] = useState(undefined)

    async function load() {
        const data = await api.get("/rank-threat-stats")
        setStats(_.groupBy(data.data, a => {
            return moment(a.criteria?.[0]?.date).startOf('day').format();
        }))
    }

    useEffect(() => {
        load().then()
    }, [])


    const back = async () => {
        window.location.href = "/brand-protection/dashboard"
    }


    if (Object.keys(stats||{})?.length == 0) {
        return <div>
            <br/>
            <Card css={css`
                    color: #28B446;
                    `}>
                <CheckOutlined/>
                <span>{" "}No Competitors right now, your Brand is protected, We are going to pause your Ad in a moment.</span>
            </Card>
            <br/>
            <Button type="link" onClick={back}>back</Button>
        </div>
    }
    return <AdWordsLoading loading={!stats}>
        <BrandProtectionThreatsRenderer stats={stats}/>
    </AdWordsLoading>
}

