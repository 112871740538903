/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import {Button, Card, Checkbox, Form, Input} from "antd";
import React, {useContext} from "react";
import {Billing} from "../components/Billing";
import {BrandProtectionsList} from "@front-component";

const Style = styled.div`
height: 100%;
`

export function BillingPage(){

    return <Style>
            <Billing/>
    </Style>
}

