import React, {useEffect, useState} from 'react';
import {MeDTO} from "@backend-lib";

export type UserContextTypeContent = { user?: MeDTO };
export type UserContextType = {
    user?: MeDTO,
    setUser?: any
    reset?: any
};

export const UserContext = React.createContext({} as UserContextType);
export const UserContextProvider = (props: { children: any, user?: any }) => {
    const [user, setUser] = useState(props.user as MeDTO)

    useEffect(() => {
        setUser(props.user)
    }, [JSON.stringify(props.user)])

    function reset() {
        setUser(undefined as any)
    }

    return (
        <UserContext.Provider
            value={{
                user, setUser, reset
            }}
        >
            {props?.children}
        </UserContext.Provider>
    );
};
