import React from 'react';
import {AxiosInstance} from "axios";

export type AxiosContextTypeContent = { axios?: any};
export type AxiosContextType = {
    api?: AxiosInstance,
};

export const AxiosContext = React.createContext({} as AxiosContextType);
export const AxiosContextProvider = (props: { children: any, api?: AxiosInstance }) => {
    return (
        <AxiosContext.Provider value={{api: props.api}}>
            {props?.children}
        </AxiosContext.Provider>
    );
};
