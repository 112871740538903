/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from '@emotion/styled';
import {Button, Card, Checkbox, Divider, Form, notification, Select} from "antd";
import Search from "antd/lib/input/Search";
import * as _ from "lodash";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {
    AdWordsStrictLoading,
    AxiosContext,
    AxiosContextType,
    getBudget,
    getDeviceCriteria,
    getFlag,
    getGeoCriteria,
    getKeywordsCriteria,
    getLanguageCriteria,
    GoogleCampaigns,
    GoogleLogo,
    trackProtection,
    useLoading,
    UserContext
} from "@front-component";

function GeoCriteriaSelector({geoCriteria, setGeoCriteria}:any) {
    return <Select
        css={css`min-width:100px`}
        mode={"multiple"}
        allowClear={true}
        value={geoCriteria.filter(e => e.active).map(e => e.name)}
        onChange={
            (selectedCountries) => {
                setGeoCriteria(geoCriteria.map(g => {
                    g.active = selectedCountries.includes(g.name)
                    return g
                }));
            }}
        placeholder={geoCriteria.length === 0 ? "No country specified" : "Select countries"}>
        {geoCriteria.map(geo => (
            <Select.Option selected={geo.active}
                           value={geo.name}>
                <div css={css`display: flex;gap:1em;`}>
                    <span>{(getFlag(geo.countryCode) as any)?.emoji}</span>
                    <span>{geo.canonicalName}</span>
                </div>
            </Select.Option>))}
    </Select>;
}

function KeywordsSelector({keywordsCriteria, setKeywordsCriteria}) {
    return <Select
        css={css`min-width:100px`}
        value={keywordsCriteria.filter(e => e.active).map(e => e.text)}
        mode={"tags"}
        allowClear={true}
        onChange={
            (selectedKeywords) => {
                setKeywordsCriteria(selectedKeywords.map(k => ({
                    active: true,
                    matchType: 2,
                    text: k,
                })));
            }}
        placeholder={keywordsCriteria.length === 0 ? "No keyword specified" : "Select keywords"}>
        {keywordsCriteria.map(key => (
            <Select.Option selected={key.active}
                           key={key.text}
                           value={key.text}>
                <div css={css`display: flex;gap:1em;`}>
                    <span>{key.text}</span>
                </div>
            </Select.Option>))}
    </Select>;
}


function DevicesSelector({deviceCriteria, setDeviceCriteria}) {
    return <Select
        css={css`min-width:100px`}
        value={deviceCriteria.filter(e => e.active).map(e => e.text)}
        mode={"multiple"}
        allowClear={true}
        onChange={
            (selectedKeywords) => {
                (deviceCriteria.map(k => {
                    k.active = selectedKeywords.includes(k.text)
                    return k
                }));
            }}
        placeholder={deviceCriteria.length === 0 ? "All devices" : "Select devices"}>
        {deviceCriteria.map(device => (
            <Select.Option selected={device.active}
                           value={device.text}>
                <div css={css`display: flex;gap:1em;`}>
                    <span>{device.text}</span>
                </div>
            </Select.Option>))}
    </Select>;
}

function LanguagesSelector({languageCriteria, setLanguageCriteria}) {
    return <Select
        css={css`min-width:100px`}
        value={languageCriteria.filter(e => e.active).map(e => e.code)}
        mode={"multiple"}
        allowClear={true}
        onChange={
            (selectedLanguages) => {
                setLanguageCriteria(languageCriteria.map(k => {
                    k.active = selectedLanguages == k.code
                    return k
                }));
            }}
        placeholder={languageCriteria.length === 0 ? "All languages" : "Select languages"}>
        {languageCriteria.map(lang => (
            <Select.Option selected={lang.active}
                           value={lang.code}>
                <div css={css`display: flex;gap:1em;`}>
                    <span>{lang.name}</span>
                </div>
            </Select.Option>))}
    </Select>;
}

function BudgetSelector({value}) {
    return <Select
        css={css`min-width:100px`}
        defaultValue={value}
        disabled={true}>
        <Select.Option selected={true}
                       value={value}>
            <div css={css`display: flex;gap:1em;`}>
                <span>{value}</span>
            </div>
        </Select.Option>
    </Select>;
}

const FormItemContainer = styled.div`
font-size: 14px;
.ant-select{
 // width: 400px;
}
  >div{
  margin-top: 11px;
  }

`

function GeoCriteriaFormItem(props: { geoCriteria: unknown, setGeoCriteria: (value: unknown) => void }) {
    return <FormItemContainer>
        <Form.Item label={"Countries"}
                   tooltip={"Countries that are going to be monitor by GetRank, when a competitor happens it will run your Ads on this country."}>
            <GeoCriteriaSelector geoCriteria={props.geoCriteria} setGeoCriteria={props.setGeoCriteria}/>
        </Form.Item>
    </FormItemContainer>;
}

function KeywordsFormItem(props: { keywordsCriteria: unknown, keywordsCriteria1: (value: unknown) => void }) {
    return <FormItemContainer>
        <Form.Item label={"Keywords"}
                   tooltip={"Keywords that are going to be monitor by GetRank, when a competitor happens it will run your Ads on this keyword."}>
            <KeywordsSelector keywordsCriteria={props.keywordsCriteria} setKeywordsCriteria={props.keywordsCriteria1}/>
        </Form.Item>
    </FormItemContainer>;
}

function DevicesFormItem(props: { deviceCriteria: unknown, deviceCriteria1: (value: unknown) => void }) {
    return <FormItemContainer>
        <Form.Item label={"Devices"}
                   tooltip={"Devices that are going to be monitor by GetRank, when a competitor happens it will run your Ads on this device."}>
            <DevicesSelector deviceCriteria={props.deviceCriteria} setDeviceCriteria={props.deviceCriteria1}/>
        </Form.Item>
    </FormItemContainer>;
}

function LanguagesFormItem(props: { languageCriteria: unknown, languageCriteria1: (value: unknown) => void }) {
    return <FormItemContainer>
        <Form.Item label={"Languages"}
                   tooltip={"Languages that are going to be monitor by GetRank, when a competitor happens it will run your Ads on this language."}>
            <LanguagesSelector languageCriteria={props.languageCriteria} setLanguageCriteria={props.languageCriteria1}/>
        </Form.Item>
    </FormItemContainer>;
}

function BudgetFormItem(props: { budgetCriterion: any }) {
    return <FormItemContainer>
        <Form.Item label={"Budget"}
                   tooltip={"Maximum daily Budget. Retrieve from your Google Ads Campaign. You can manage it using your Google Ads Dashboard"}>
            <div css={css`min-width:100%`}>
                <BudgetSelector value={`${(props.budgetCriterion?.amountMicros || 0) / 1000000}$/day`}></BudgetSelector>
            </div>
        </Form.Item>
    </FormItemContainer>;
}

function SimulationFormItem(props: { simulation: boolean, setSimulation:any }) {
    return <FormItemContainer>
        <Form.Item css={css`.ant-form-item-control{flex-direction: row;gap:1em; align-items:center}`}
                   extra={"Activate email alerts without launching my ad protection."}
                   label={" "}
                   valuePropName={"checked"}
                   initialValue={props.simulation}
                   tooltip={"If activated, you will have no changes  on your Google Ads Campaign, but useful notification emails "}>
                <Checkbox defaultChecked={props.simulation} onChange={(e)=>props.setSimulation(e.target.checked)}/>
        </Form.Item>
    </FormItemContainer>;
}

function getActive(criteria: any[]) {
    return criteria.filter(k => k.active);
}

export function EmptyAdsPreview(props: { keywordsCriteria: any[], country: string, countryFlag: string }) {
    return <div css={css`
padding:1em;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
border-radius: 3px;
width: 500px;
>div{
margin-bottom: 0.5em;
}
`}>
        <div css={css`display: flex;gap:1em; align-items:center; justify-content:space-between`}>
            <div css={css`display: flex;gap:1em; align-items:center`}>
                <GoogleLogo/>
                <Search
                    placeholder={props.keywordsCriteria[0].text} css={css`width:200px`}/>
            </div>
            <span css={css`display: flex;gap:1em; align-items:center; justify-content:space-between`}><span css={css`font-size:30px`}>{props.countryFlag}</span></span>
        </div>
        <div css={css`display: flex;gap:1em; align-items:center`}>
            <br/>
            <br/>
            <br/>
            <div className={"ant-form-item-explain"}
            css={css`font-size: 10px; text-align: center; width: 100%`}> {"No english ad detected in " + props.country}</div></div>
    </div>;
}


export function AdsPreview(props: { keywordsCriteria: any[], ad: any, countryFlag: string }) {
    return <div css={css`
padding:1em;
box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
border-radius: 3px;
width: 500px;
>div{
margin-bottom: 0.5em;
}
`}>
        <div css={css`display: flex;gap:1em; align-items:center; justify-content:space-between`}>
            <div css={css`display: flex;gap:1em; align-items:center`}>
                <GoogleLogo/>
                <Search
                    placeholder={props.keywordsCriteria[0].text} css={css`width:200px`}/>
            </div>
            <span css={css`display: flex;gap:1em; align-items:center; justify-content:space-between`}><span css={css`font-size:30px`}>{props.countryFlag}</span></span>
        </div>
        <div css={css`display: flex;gap:1em; align-items:center`}><span
            css={css`font-size:12px;font-weight:bold`}>Ad</span><span
            css={css`font-size:10px`}>{props.ad.adData.destinationUrl || props.ad.adData.displayUrl}</span>
        </div>
        <a css={css`    color: #1a0dab;    font-size: 12px;
    line-height: 26px;`}
           href={props.ad.adData.destinationUrl}>{props.ad.adData?.headlines?.map(h => h.text)?.join("| ") || props.ad.adData?.title}</a>
        <div
            css={css`font-size: 10px;`}> {props.ad.adData.descriptions?.map(h => h.text)?.join("| ") || props.ad.adData.description}</div>
    </div>;
}

export function BrandProtectionEdit({selectedProtectionId, customerId}) {
    console.log("redraw BrandProtectionEdit " + selectedProtectionId)

    const userCtx = useContext(UserContext)
    const {user} = userCtx
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {doAction, loading} = useLoading(true)
    const {doAction:doActionCreate, loading:loadingCreate} = useLoading(false)


    const [baseCampaign, setBaseCampaign] = useState(undefined as any)
    const [geoCriteria, setGeoCriteria] = useState([])
    const [keywordsCriteria, setKeywordsCriteria] = useState([])
    const [budgetCriterion, setBudgetCriterion] = useState(undefined)
    const [simulation, setSimulation] = useState(false)
    const [deviceCriteria, setDeviceCriteria] = useState([])
    const [languageCriteria, setLanguageCriteria] = useState([])
    const [active, setActive] = useState(false)
    const [beta, setBeta] = useState(false)
    const [protection, setProtection] = useState(undefined)

    if (window.location.href.includes("beta")) {
        if (beta !== true) {
            setBeta(true)
        }
    }
    const load = () => doAction(async () => {
        if (selectedProtectionId) {
            const protection = (await api.get("/protection/" + selectedProtectionId)).data
            setBaseCampaign(protection.baseCampaign)
            let {availableGeoTargets, atLeastOneActive, geoCriteria} = await getAvailableGeoTargets(protection.baseCampaign);

            setGeoCriteria(_.uniqBy([...protection.geoCriteria, ...geoCriteria.map(g=>{
                g.active = false;
                return g
            })], "id"))
            setKeywordsCriteria(protection.keywordsCriteria)
            setBudgetCriterion(protection.budgetCriterion)
            setDeviceCriteria(protection.deviceCriteria)
            setLanguageCriteria(protection.languageCriteria)
            setActive(protection.active)
            if(protection.mode === "SIMULATION") {
                setSimulation(true)
            }
            setProtection(protection)
        }
    })

    const back = async () => {
        window.location.href = "/brand-protection/dashboard"
    }

    async function getAvailableGeoTargets(campaign) {
        let availableGeoTargets = (await api.get("/geo-target")).data
        let atLeastOneActive = false
        let geoCriteria = availableGeoTargets.map(geo => {
            geo.active = getGeoCriteria(campaign).map(c => c.name).includes(geo.name)
            atLeastOneActive = geo.active || atLeastOneActive
            return geo
        })
        return {availableGeoTargets, atLeastOneActive, geoCriteria};
    }

    const setSelectedGoogleCampaign = async (campaign) => {
        //const data = await api.patch("/account/" + user.account.id,{selectedGoogleCampaigns: campaigns})
        let {availableGeoTargets, atLeastOneActive, geoCriteria} = await getAvailableGeoTargets(campaign);
        setBaseCampaign(campaign)
        if (!atLeastOneActive) {
            geoCriteria = availableGeoTargets.map(geo => {
                geo.active = geo.default
                return geo
            })
        }
        setGeoCriteria(geoCriteria)

        setKeywordsCriteria(getKeywordsCriteria(campaign).map(k => {
            k.active = true;
            return k;
        }))
        setLanguageCriteria(getLanguageCriteria(campaign).map(g => {
            g.active = true;
            return g;
        }))
        setDeviceCriteria(getDeviceCriteria(campaign).map(g => {
            g.active = true;
            return g;
        }))
        setSimulation(false)
        setBudgetCriterion(getBudget(campaign))
        setActive(campaign.active)

        notification.success({
            message: "Protection initialized",
            description: <Fragment>We preconfigured your <b>Brand Protection</b> with your Google Ads campaign data<br/>You
                can change the criteria to match with your needs.</Fragment>,
            duration: 20,
        });
    }

    const handleScrape = async () => {
        const results = (await api.patch("/rank-threat-handler/protection/" + protection?.id)).data
        return
    }

    const scrapeProtection = async () => {
        const results = (await api.patch("/scraper/protection/" + protection?.id)).data
        return
    }

    const createProtection = () => doActionCreate(async () => {

        if (getActive(keywordsCriteria)?.length > 3) {
            notification.error({
                message: "Too many keywords selected",
                description: "You can monitor maximum 3 keywords",
            });
            return
        }
        const max = parseInt(user.account?.orga?.metadata?.maxCountries || user.account?.orga?.subscription?.plan?.metadata?.maxCountries || "10")
        if (getActive(geoCriteria)?.length > max) {
            notification.error({
                message: "Too many countries selected",
                description: "You can monitor maximum "+max+", selected: "+getActive(geoCriteria)?.length+", please upgrade your subscription",
            });
            return
        }
       /* if (getActive(languageCriteria)?.length >= 2) {
            notification.error({
                message: "Too many languages selected",
                description: "You can monitor maximum 2 languages",
            });
            return
        }*/
        let savedProtection
        if (protection?.id) {
            savedProtection = (await api.patch("/protection/" + protection?.id, {
                baseCampaign,
                keywordsCriteria: getActive(keywordsCriteria),
                geoCriteria: getActive(geoCriteria),
                budgetCriterion,
                //languageCriteria: getActive(languageCriteria),
                deviceCriteria: getActive(deviceCriteria),
                active: false,
                id: protection?.id,
                mode: simulation?"SIMULATION":"AUTO"
            })).data
            trackProtection("protection-edited", savedProtection)

        } else {
            savedProtection = (await api.post("/protection", {
                baseCampaign,
                keywordsCriteria: getActive(keywordsCriteria),
                geoCriteria: getActive(geoCriteria),
                budgetCriterion,
                languageCriteria: getActive(languageCriteria),
                deviceCriteria: getActive(deviceCriteria),
                active: false,
                mode: simulation?"SIMULATION":"AUTO"
            })).data
            savedProtection = {...protection, ...savedProtection}
            await trackProtection("clicked-protect-mybrand", savedProtection)
            setProtection(savedProtection)
        }
        savedProtection = (await api.patch("/protection/" + savedProtection.id, {
            active: true
        })).data
        savedProtection = {...protection, ...savedProtection}
        setProtection(savedProtection)

        window.location.href = "/brand-protection/dashboard"
        return
    })

    useEffect(() => {
        console.log("redraw useEffect start BrandProtectionEdit ")
        load().then(() => console.log("redraw useEffect end BrandProtectionEdit "))
    }, [])

    if (baseCampaign && baseCampaign.googleAdsCampaign) {
        return <AdWordsStrictLoading loading={loading}>
            <Form layout={"vertical"}>
                <Fragment>
                    <Card title={"Create your brand protection"} bordered={false}>
                <span>Select countries, keywords, devices & languages you would like to monitor. <a
                    href={"https://getrank.io"}>How brand protection works?</a> </span>
                        <br/>
                        <br/>
                        <div css={css`grid-template-columns: 1fr 1fr;display: grid; column-gap: 1em;`}>
                            <GeoCriteriaFormItem geoCriteria={geoCriteria} setGeoCriteria={setGeoCriteria}/>
                            <KeywordsFormItem keywordsCriteria={keywordsCriteria}
                                              keywordsCriteria1={setKeywordsCriteria}/>
                            {/*<LanguagesFormItem languageCriteria={languageCriteria}
                                               languageCriteria1={setLanguageCriteria}/>*/}
                            <BudgetFormItem budgetCriterion={budgetCriterion}/>
                            <SimulationFormItem simulation={simulation} setSimulation={setSimulation}/>
                        </div>
                        <div css={css``}>
                            <Divider/>
                            <div css={css`display: flex;justify-content:flex-end;width:100%;align-items:center`}>
                                <Button type="link" onClick={back}>back</Button>
                                <Button size={"large"}
                                        disabled={getActive(keywordsCriteria).length === 0 || getActive(geoCriteria).length === 0}
                                        loading={loadingCreate} type={"primary"} onClick={createProtection}>Protect my
                                    brand</Button>
                            </div>
                        </div>
                        <Divider type={"horizontal"}/>
                        {keywordsCriteria?.length > 0 &&
                        <div>
                            <h2>Ad preview</h2>
                            <AdsPreview keywordsCriteria={keywordsCriteria}
                                         countryFlag={""}
                                        ad={baseCampaign.googleAdsCampaign?.adGroupAd?.ad}/>
                            <br/>
                        </div>
                        }
                        {beta &&
                        <Fragment>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Button type={"primary"} onClick={scrapeProtection}>Verify competitor</Button>
                            <Button type={"primary"} onClick={handleScrape}>Configure campaign with last scrape</Button>
                        </Fragment>
                        }
                    </Card>
                </Fragment></Form>
        </AdWordsStrictLoading>
    } else {
        //
        return <AdWordsStrictLoading loading={loading}><Fragment>
            <Card title={"Create your brand protection"} bordered={false}>
                <p>Select a brand protect campaign. <a>Need help to configure a brand protect campaign? </a></p>
                {customerId && <GoogleCampaigns onSelect={setSelectedGoogleCampaign}
                                 customerId={customerId}/>}
            </Card>
        </Fragment>
        </AdWordsStrictLoading>
    }

}
