import {AxiosInstance, AxiosResponse} from "axios";
import {UserDTO} from "../lib";

export interface ChangePasswordDTO {
    newPassword: string;
}

export class UserAPI {
    static async changePassword(
        axios: AxiosInstance,
        changePassword: ChangePasswordDTO
    ): Promise<AxiosResponse<UserDTO>> {
        try {
            const response = await axios.put<UserDTO>(
                `/users/change-password`,
                changePassword
            );
            return response;
        } catch (error) {
            throw new Error(
                error.response?.data?.message || "An error occurred"
            );
        }
    }
}