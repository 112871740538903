/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import {Button, Card, Popconfirm} from "antd";
import axios from "axios";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Flex, useLoading, useUser, UserContext} from "@front-component";

const Style = styled.div`
background-color: #FAFBFB;
padding-top: 200px;
min-height:100%;

.ant-card-head {
border: none;
}

.ant-card{
text-align:center; 
margin: auto;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
padding-bottom: 3em;
}

.ant-card-body{
  color: #3F3F3F;
}

`

export function Billing(){

    const [subscription,setSubscription] = useState(undefined)
    const [prices,setPrices] = useState([])
    const {doAction,loading} = useLoading(false)
    const {user, setUser, reset} = useContext(UserContext)
    const {loadMe} = useUser({user, setUser, reset});

    async function load(){
        const subscription = (await axios.get("/api/bank/subscription")).data
        setSubscription(subscription)
        const prices = (await axios.get("/api/bank/prices")).data
        setPrices(prices)
    }
    useEffect(() =>{
        load().then()
    },[])

    const upgradeSubscription= (prices:any[]) =>doAction(async ()=> {
        if(subscription?.plan.amount === 0){
            window.location.href = `/checkout`
            return
        }
        await axios.patch(`/api/bank/subscription/upgrade`,{pricesIds:prices.map(p=>p.id)})
        await load().then()
        await loadMe()
        if(prices[0]?.metadata?.type == "partner"){
            setTimeout(()=>{
                window.location.reload()
            },2000)
        }

    })

    const partnerPrices = prices.filter(p=>p.interval == "month" &&
                p.amount>=subscription?.plan?.amount &&
                p.metadata?.enabled == "1"
            ).sort((a,b)=>a.amount-b.amount)

    return (
            <Style>
                <Card css={css`
`}
                      title={"Simple pricing to protect your brand"}  bordered={false}>
                <p>30-Day Money-Back Guarantee</p>
                    <Flex style={{width:"fit-content",
                        margin:"auto",
                        alignItems:"stretch",
                        justifyContent:"stretch",
                        position:"relative",
                        height:300}}>
                    {subscription?.plan &&
                        <Card css={css`
                            height: 100%;
                            min-width: 250px;
                            background: rgba(22, 119, 255, 0.1);
                        `} bordered={false}
                              title={<Fragment>
                                  <div style={{margin:20}}>{subscription?.plan?.metadata?.displayName}</div>
                                  <div>${(subscription?.plan.amount || 0) / 100} /month</div>
                              </Fragment>} extra={""}
                        >

                            {subscription?.plan?.metadata?.description}
                            {! subscription?.plan?.metadata?.description &&<ul css={css`text-decoration: none;
                                list-style-type: none;
                                padding: 0`}>
                                <li><b>1</b> brand protected</li>
                                <li>Google Ads monitor</li>
                                <li>Google Ads launcher</li>
                            </ul>}
                            <br/>
                            <Button disabled={true} style={{position:"absolute", bottom:0, left:0, margin:20, width:"calc( 100% - 40px )"}} size={"large"}>Current Plan</Button>
                        </Card>
                    }

                        <Card css={css`
                            height: 100%;
                            max-width: 250px;
                            min-width: 250px;
                        `} bordered={false}
                                     title={partnerPrices.map(plan=><Fragment key={plan.id}>
                                         <div style={{padding:5, fontSize:14}}>
                                         <div style={{margin:2, fontSize:15}}>{plan?.metadata?.displayName}</div>
                                         <div style={{fontWeight:400}}>${(plan.amount || 0) / 100} /month {plan?.metadata?.type=="partner"?" /subscriber":""}</div>
                                         </div>
                                     </Fragment>)} extra={""}
                        >
                            <ul css={css`text-decoration: none;
                                list-style-type: none;
                                padding: 0`}>
                                <li>Partner subscription to 3 or 10 keywords</li>
                            </ul>
                            <br/>
                            <Popconfirm title={<Fragment>Are you sure you want to upgrade?
                                <div style={{fontSize:10}}>This will remove all your current protections <br/>and turn your account into a Partner account.</div></Fragment>}


                                        onConfirm={()=>upgradeSubscription(partnerPrices)} okText="Yes" cancelText="No">
                            <Button
                                loading={loading}
                                style={{position:"absolute", bottom:0, left:0, margin:20, width:"calc( 100% - 40px )"}}
                                size={"large"}
                                type={"primary"}>Upgrade Subscription</Button>
                            </Popconfirm>
                        </Card>
                    </Flex>
                </Card>

            </Style>
    )
}

