/** @jsxRuntime classic */
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import Modal from 'antd/lib/modal';
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AxiosContext, GoogleLogo, track} from "@front-component";
import {LoginPage} from "../page/LoginPage";
import axios from "axios";

export function NoAdsGoogleAccount(){
    const {api} = useContext(AxiosContext)
    const [visible,setVisible] = useState(true)

    useEffect(() => {
        async function load() {
            track("no-ads-google-account",{})
            const data = await axios.delete("/api/auth/login")
        }
    load().then()
    },[])

    return <Fragment>
        <Modal open={visible} onOk={()=>setVisible(true)} cancelButtonProps={{disabled: true}}>
            <div >
                <GoogleLogo/>
                <h3>Your Google Account doesn't seems to be link to Google Ads Account.</h3>
                <br/>
                <a target={"_blank"} href={"https://ads.google.com/nav/selectaccount?sourceid=emp&subid=fr-fr-et-g-aw-a-tools-ma-awhp_xin1!o2&pli=1"}>Please verify your account here</a>
            </div>
        </Modal>

            <LoginPage/>
    </Fragment>

}
