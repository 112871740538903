/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from "@emotion/styled";
import {Button, Card, Divider, Form, Input} from "antd";
import React, {useContext, useEffect} from "react";
import {AdWordsLoading} from "@front-component";
import {ReferralImage} from "@front-component";
import {AxiosContext, AxiosContextType} from "@front-component";
import {useLoading,SecureIcon} from "@front-component";
import {ReferralAPI} from "@backend-lib";

const Style = styled.div`
text-align: left;
width: 90%;
margin: auto;


`

export function Referral({protectionId}) {

    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {doAction, loading} = useLoading()

    async function load() {
    }

    async function addReferral(values) {
        await new ReferralAPI(api).addReferral(protectionId, values)
        window.location.href = "https://www.getrank.io/protect-your-brand-getrank-io"
    }

    useEffect(() => {doAction(async () => {
        await load().then()
    })}, [])

    return <AdWordsLoading loading={loading} tip={"Loading"}>
        <Style>
                <Card css={css`max-width:1024px; margin: auto; padding-left: 2em; padding-right: 2em`}>
                    <div css={css`display:flex;justify-content: space-between;`}>
                        <div css={css`flex: 0 0 400px;text-align: left`}>
                            <Form onFinish={(values) => doAction(async () => addReferral(values))}
                                  layout={"vertical"}>
                                <div css={css`display:flex; justify-content: space-between;`}>
                                    <Form.Item
                                        name="firstName"
                                        label="Your first name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your first name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Tim"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="lastName"
                                        label="Your last name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your last name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Cook"/>
                                    </Form.Item>
                                </div>
                                <Divider css={css`margin-top:-10px;margin-bottom:5px `}/>
                                <Form.Item
                                    name="email1"
                                    label="Email of your friend"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: 'Please input your friend email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="tim@apple.com"/>
                                </Form.Item>
                                <Form.Item
                                    name="email2"
                                    label="Email of your friend"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: 'Please input your friend email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="tim@apple.com"/>
                                </Form.Item>
                                <Form.Item
                                    name="email3"
                                    label="Email of your friend"
                                    rules={[
                                        {
                                            required: false,
                                            type: 'email',
                                            message: 'Please input your friend email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="tim@apple.com"/>
                                </Form.Item>
                                <Form.Item>
                                    <Button css={css`width:100%`} size={"large"} type={"primary"} htmlType="submit">Invite my friends</Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div css={css`flex: 0 1 300px; max-height: 500px;text-align: end;`}>
                            <ReferralImage/>
                        </div>
                    </div>
                </Card>
                <Card css={css`max-width:1024px; margin: auto; padding-left: 2em; padding-right: 2em`}>
                    <div css={css`display:flex; justify-content: space-between`}>
                        <div css={css`flex: 0 0 400px; text-align: left`}>
                            <h1>Protect your brand.</h1>
                            <div>GetRank.io monitors your brand name on Google & <b>automatically launches an ad on Adwords</b>
                                if someone is bidding on your name.

                            </div>
                            <br/>
                            <div>
                                <Button css={css`width:200px`}  size={"large"}
                                        onClick={() => window.location.href = "https://www.getrank.io"}>Learn more</Button>
                            </div>
                        </div>
                        <div css={css`flex: 0 1 300px;text-align: end;`}>
                            <SecureIcon/>
                        </div>
                    </div>
                </Card>
            <br/>
        </Style>
    </AdWordsLoading>
}



