import {Fragment} from "react";
import {Logo} from "@front-component";

export function PartnerSubscriberValidatePage() {
    return <Fragment>
        <div style={{textAlign:"center"}}>
        <Logo/>
        <br/>
        <h1>Congratulations, your susbcriber account is now validated.</h1>
        </div>
    </Fragment>
}