/** @jsxRuntime classic */
/** @jsx jsx */
import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    PlayCircleOutlined,
    SecurityScanOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Button, Popconfirm} from "antd";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AdWordsStrictLoading, AxiosContext, AxiosContextType, useLoading, UserContext} from "@front-component";
import {ProtectionAPI, ProtectionDTO} from "@backend-lib";

const Style = styled.div``

export function BrandProtectionsList() {
    const {user, setUser} = useContext(UserContext)
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {doAction, loading} = useLoading()
    const [protections, setProtections] = useState([] as ProtectionDTO[])

    const load = () => doAction(async () => {
        const protections: ProtectionDTO[] = await new ProtectionAPI(api).getProtections()
        if (protections.length === 0) {
            await addProtection()
        }
        setProtections(protections)
    })

    useEffect(() => {
        load().then()
    }, [])

    async function editProtection(protectionId:number) {
        if (!protectionId) {
            return
        }
        window.location.href = "/brand-protection/edit/" + protectionId
    }

    async function removeProtection(protectionId:number) {
        if (!protectionId) {
            return
        }
        await new ProtectionAPI(api).removeProtection(protectionId)
        window.location.href = "/brand-protection/edit/"
    }

    async function addProtection() {
        window.location.href = "/brand-protection/edit/"
    }

    async function showThreats(protectionId:number) {
        window.location.href = "/brand-protection/threats/" + protectionId
    }

    async function showActions(protectionId:number) {
        window.location.href = "/brand-protection/actions/" + protectionId
    }

    return <AdWordsStrictLoading loading={loading!}>
        <Style>
            {protections.length > 0 &&
            <div css={css`
color: #28B446;
position: absolute;
top: 1em;
right: 1em;
`}><CheckOutlined/>
                <span>{" "}Your brand is protected</span>
            </div>}

            {protections.length > 0 &&
            <div css={css`
display: grid;
grid-template-columns: 1fr 1fr 1fr ;
align-items: center;
gap: 1em;
`}>
                <span css={css`color:#3F3F3F`}>Keywords</span>
                <span css={css`color:#3F3F3F`}>Budget</span>
                <div>
                    <span></span>
                    <span></span>
                </div>
                {protections.map((p: ProtectionDTO) => (
                    <Fragment>
                        <span css={css`color:#000`}>{p.keywordsCriteria.map(k => k.text).join(",")}</span>
                        <span css={css`color:#000`}>${p.budgetCriterion?.amountMicros||0 / 1000000} / day</span>
                        <div>
                            <Button shape={"round"} icon={<EditOutlined/>}
                                    onClick={() => editProtection(p.id)}>Edit</Button>
                            <Popconfirm title={"Are you sure to delete Protection?"}
                                        onConfirm={() => removeProtection(p.id)}>
                                <Button shape={"round"} icon={<DeleteOutlined/>}>Delete</Button>
                            </Popconfirm>
                            <Button shape={"round"} icon={<WarningOutlined/>}
                                    onClick={() => showThreats(p.id)}>Competitors</Button>
                            <Button shape={"round"} icon={<PlayCircleOutlined/>}
                                    onClick={() => showActions(p.id)}>Actions</Button>
                        </div>
                    </Fragment>
                ))}
            </div>
            }
            <br/>
            <Button type="primary" disabled={protections.length >= (parseInt(user?.account?.orga?.metadata?.maxBrands || user?.account?.orga?.subscription?.plan?.metadata?.maxBrands || "1")) } onClick={addProtection}
                    icon={<SecurityScanOutlined/>}>New brand protection</Button>
        </Style>
    </AdWordsStrictLoading>
}

