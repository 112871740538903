/** @jsxRuntime classic */
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Card, Timeline} from "antd";
import * as _ from "lodash";
import moment from 'moment';
import React, {useContext, useEffect, useState} from "react";
import {AdWordsLoading, AxiosContext, AxiosContextType, isGeoCriterion, isKeywordsCriterion} from "@front-component";

const Style = styled.div`

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{
  left: 220px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(220px - 4px);
    width: calc(100% - 234px);
    text-align: left;
}

.ant-timeline-item {
  display: contents;
}
`

function TimelineContent(props: { date: string, stats: any }) {
    return <Timeline.Item label={<div
        css={css`width:200px; cursor:pointer;`}>
        <br/>
        {moment(props.date).format("MMMM Do, YYYY")}
        <br/>
        <br/>
    </div>}>
        {props.stats[props.date].map(s => {
            return (<div css={css`display:flex;gap:1em; align-items:center`}>
                {isKeywordsCriterion(s) && <span>{(s.create ? "Add" : "Remove") + " keyword " + s.criterion.text}</span>}
                {isGeoCriterion(s) && <span>{(s.create ? "Add" : "Remove") + "  zone " + s.criterion.name}</span>}
            </div>)
        })}
    </Timeline.Item>;
}

export function BrandProtectionActionsPage({selectedProtectionId}) {


    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [stats, setStats] = useState(undefined)

    async function load() {
        const protectionData = (await api.get("/protection/" + selectedProtectionId))?.data
        const data = await api.get("/scrape-action")

        setStats(_.groupBy(data.data, a => {
            return moment(a.treatedAt || a.createdAt).startOf('day').format();
        }))
    }

    useEffect(() => {
        load().then()
    }, [])

    return <AdWordsLoading loading={!stats}>
        <Style>
            <Card title={"Threats"} bordered={false}>
                <h1>Actions</h1>
                <Timeline mode="left">

                    {Object.keys(stats||{}).map(k => {
                        return (
                            <TimelineContent date={k} stats={stats}/>
                        )
                    })
                    }
                </Timeline>
            </Card>
        </Style>
    </AdWordsLoading>
}


