import {AccountDTO} from "./account.dto";
// @ts-ignore
import faker from 'faker';
import {createParamDecorator, ExecutionContext} from "@nestjs/common";

export interface ReqUserDTO {
    id: string
    role: string
    email: string
    account: {
        version: number
        updatedAt: string
        createdAt: string
        id: number
        googleCustomerId: any
        selectedGoogleCustomer: any
        isDemo: boolean
        name: any
        partnerSubscription: any
        orga: {
            version: number
            updatedAt: string
            createdAt: string
            id: number
            metadata: any
            customer: {
                id: string
                name: string
                email: string
                phone: any
                object: string
                address: any
                balance: number
                created: number
                currency: any
                discount: any
                livemode: boolean
                metadata: {}
                shipping: any
                delinquent: boolean
                tax_exempt: string
                test_clock: any
                description: any
                default_source: any
                invoice_prefix: string
                invoice_settings: {
                    footer: any
                    custom_fields: any
                    rendering_options: any
                    default_payment_method: any
                }
                preferred_locales: Array<any>
                next_invoice_sequence: number
            }
            subscription: {
                id: string
                plan: any
                items: {
                    url: string
                    data: Array<{
                        id: string
                        plan: {
                            id: string
                            active: boolean
                            amount: number
                            object: string
                            created: number
                            product: string
                            currency: string
                            interval: string
                            livemode: boolean
                            metadata: {}
                            nickname: string
                            tiers_mode: any
                            usage_type: string
                            amount_decimal: string
                            billing_scheme: string
                            interval_count: number
                            aggregate_usage: string
                            transform_usage: any
                            trial_period_days: any
                        }
                        price: {
                            id: string
                            type: string
                            active: boolean
                            object: string
                            created: number
                            product: string
                            currency: string
                            livemode: boolean
                            metadata: {}
                            nickname: string
                            recurring: {
                                interval: string
                                usage_type: string
                                interval_count: number
                                aggregate_usage: string
                                trial_period_days: any
                            }
                            lookup_key: any
                            tiers_mode: any
                            unit_amount: number
                            tax_behavior: string
                            billing_scheme: string
                            custom_unit_amount: any
                            transform_quantity: any
                            unit_amount_decimal: string
                        }
                        object: string
                        created: number
                        metadata: {}
                        tax_rates: Array<any>
                        subscription: string
                        billing_thresholds: any
                    }>
                    object: string
                    has_more: boolean
                    total_count: number
                }
                object: string
                status: string
                created: number
                currency: string
                customer: string
                discount: any
                ended_at: any
                livemode: boolean
                metadata: {}
                quantity: any
                schedule: any
                cancel_at: any
                trial_end: any
                start_date: number
                test_clock: any
                application: any
                canceled_at: any
                description: any
                trial_start: any
                on_behalf_of: any
                automatic_tax: {
                    enabled: boolean
                }
                transfer_data: any
                days_until_due: any
                default_source: any
                latest_invoice: string
                pending_update: any
                trial_settings: {
                    end_behavior: {
                        missing_payment_method: string
                    }
                }
                pause_collection: any
                payment_settings: {
                    payment_method_types: any
                    payment_method_options: any
                    save_default_payment_method: string
                }
                collection_method: string
                default_tax_rates: Array<any>
                billing_thresholds: any
                current_period_end: number
                billing_cycle_anchor: number
                cancel_at_period_end: boolean
                cancellation_details: {
                    reason: any
                    comment: any
                    feedback: any
                }
                current_period_start: number
                pending_setup_intent: any
                default_payment_method: string
                application_fee_percent: any
                pending_invoice_item_interval: any
                next_pending_invoice_item_invoice: any
            }
            name: any
            type: string
            accounts: Array<{
                version: number
                updatedAt: string
                createdAt: string
                id: number
                googleCustomerId: any
                selectedGoogleCustomer: any
                isDemo: boolean
                name?: string
                partnerSubscription?: string
            }>
        }
    }
    iat: number
    exp: number
    orga: {
        id: number
    }
}

export const User = createParamDecorator(
    (data: unknown, ctx: ExecutionContext):ReqUserDTO => {
        // Retrieve the config value from AppConfigService
        const req = ctx.switchToHttp().getRequest()
        // Return the config value
        return req.user as ReqUserDTO;
    },
);
export interface UserDTO {
    id: string;
    googleId: string | null;
    account: AccountDTO;
    email: string;
    pictureUrl: string | null;
    role: any;
    isDemo: boolean;
    demoCredit: number;
    referrer: UserDTO | null;
    referrals: UserDTO[];
    firstName: string | null;
    lastName: string | null;
    emailValidated: boolean | null;
    validationToken: string | null;
    // Excluding the 'password' property from the DTO
    // as it should not be exposed externally
}

export function generateFakeUserDTO(): UserDTO {
    return {
        id: faker.datatype.uuid (),
        googleId: faker.datatype.boolean() ? faker.datatype.uuid () : null,
        account: undefined as any,
        email: faker.internet.email(),
        pictureUrl: faker.datatype.boolean() ? faker.image.avatar() : null,
        role: {}, // Replace with appropriate data for the 'role' property
        isDemo: faker.datatype.boolean(),
        demoCredit: faker.datatype.number(),
        referrer: undefined as any,
        referrals: [],
        firstName: faker.datatype.boolean() ? faker.name.firstName() : null,
        lastName: faker.datatype.boolean() ? faker.name.lastName() : null,
        emailValidated: faker.datatype.boolean(),
        validationToken: faker.datatype.boolean() ? faker.datatype.uuid () : null,
    };
}