/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react'
import styled from "@emotion/styled";
import {Button, Card, Modal} from "antd";
import {Logo} from "@front-component";
import {FreeCompetitorCheck} from "./FreeCompetitorCheck";
import {FreeCompetitorCheckInProgress} from "./FreeCompetitorCheckInProgress";
import {Referral} from "./Referral";

const Style = styled.div`
display: flex;
gap: 4em;
align-items: center;
flex-direction: column;
padding-top: 3em;

`

export function FreeCompetitorCheckInProgressPage({protectionId}){
    return <Style>
        <Logo  css={css`zoom:1.6`}/>
        <div
            css={css`text-align: center; width: 100%;`}>
            <h1 css={css`font-weight: normal`}>We are going to check your brand name! <br/>Here's your position in the queue:
                <div css={css`display:inline-block;background-color: #1890ff;color: white; border-radius: 50%; width:50px;height:50px;font-size: 20px;line-height:50px;margin:0.5em;margin-left:1em`}><b>{Math.floor(Math.random()*100)}</b></div></h1>
            <div className={"ant-form-item-explain"}>
                <b>To speed up your results</b>, invite your friends, they will get free credits too!
            </div>
            <br/>
            <Referral protectionId={protectionId}/>
        </div>
    </Style>
}
