/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Card, Col, Divider, Row, Statistic, Timeline} from "antd";
import * as _ from "lodash";
import React, {Fragment, useState} from "react";
import moment from "moment/moment";
import {PlusCircleOutlined} from "@ant-design/icons";
import styled from "@emotion/styled";
import {AdsPreview} from "../protections";
import {getFlag} from "../../utils";

export function BrandProtectionThreatsRenderer({stats}:{stats:any[]}){
    return        <Style>
        <Card title={"Threats"} bordered={false}>
            <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic title="Competitors Threats" value={Object.keys(stats||{}).length}/>
                    </Card>
                </Col>
            </Row>
            <br/>
            <br/>

            <h1>Competitors</h1>
            <Timeline mode={"left"}>
                {Object.keys(stats||{})?.map((k:any) => {
                    return (
                        <TimeLineContent date={k} statsGrouped={_.uniqBy(stats[k], (s: any) => s.title + " " + s.description)}/>
                    )
                })
                }
            </Timeline>
        </Card>
    </Style>
}

function TimeLineContent(props: { date: string, statsGrouped: any }) {

    const [visible, setVisible] = useState(moment(props.date).isSame(moment(new Date()).add(-2,"days"), "day"))

    return <Timeline.Item css={css``}
                          label={<div
                              css={css`width:200px; cursor:pointer;`} onClick={() => setVisible(!visible)}>
                              <br/>
                              {moment(props.date).format("MMMM Do, YYYY")}
                              <br/>
                              <br/>
                          </div>}
    >
        <Fragment>
            {!visible && (<div css={css`display: flex; gap:1em; align-items: center; cursor:pointer;`} onClick={() => setVisible(!visible)}>
                {props.statsGrouped.length+" on your name"}
                {!visible && <PlusCircleOutlined />}</div>)}
            {visible && props.statsGrouped?.map((s:any) => (
                <Fragment>
                    <div css={css`display: flex; gap:1em`}>
                        <AdsPreview keywordsCriteria={s.criteria.map((c:any) => c.keywords)}
                                    countryFlag={s.criteria.map((c:any) => (getFlag(c.country.countryCode) as any)?.emoji)?.[0] }
                                    ad={{adData: s}}/>
                        <div>
                            {s.criteria.map((c:any) => (
                                <div css={css`display: flex; gap:1em`}>
                                    <div>{s.criteria.map((c:any) => (getFlag(c.country.countryCode) as any)?.emoji)?.[0] + " "}</div>
                                    <div>{s.criteria.map((c:any) => c.keywords.text).join(',')}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Divider/>
                </Fragment>)
            )
            }
        </Fragment>
    </Timeline.Item>;
}

const Style = styled.div`

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{
  left: 220px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(220px - 4px);
    width: calc(100% - 234px);
    text-align: left;
}

.ant-timeline-item {
  display: contents;
}

`