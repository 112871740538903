import {AxiosInstance} from "axios";
import {ProtectionDTO} from "../lib";


export class  ReferralAPI{
    static getInstance: (api: AxiosInstance) => ReferralAPI;

    constructor(private api?: AxiosInstance) {
    }


    async addReferral(protectionId, values) {
        return await this.api?.patch(`/public/referral/from-protection/${protectionId}`, values)
    }
}

ReferralAPI.getInstance = (api: AxiosInstance) => {
    return new ReferralAPI(api)
}
