import {Config, Configs} from "./config.def";

export const getConfig = ():Config|undefined => {
    if ((window as any).location.hostname === "localhost" || (window as any).location.hostname === "127.0.0.1") {
        return Configs.find((config) => config.name === "default");
    } else {
        return Configs.find((config) => config.name === "production");
    }
}

