let active = true;
declare global {
    interface Window { amplitude: any;Intercom }
}
export function track(event: string, payload: any) {
    if (!active) {
        console.info("FAKE ANALYTICS => track", event, payload);
        return;
    }
    // @ts-ignore
    if($crisp) {
        // @ts-ignore
        $crisp.push(["set", "session:event", [[[event, JSON.stringify(payload)]]]])
    }
    window.Intercom("trackEvent", event, payload);
    window.amplitude.getInstance().logEvent(event, payload);
}

export function trackProtection(event: string, protection: any) {
    const payload = {
        "keywords-follow":protection.keywordsCriteria.map(k=>k.text).join(','),
        "countries-follow":protection.geoCriteria.map(k=>k.name).join(','),
        "languages-follow":protection.languageCriteria.map(k=>k.code).join(','),
        "devices-follow":protection.deviceCriteria.map(k=>k.name).join(','),
        "daily-budget":protection.budgetCriterion?.amountMicros,
    }
    return track(event,payload)
}


export function identify(userId: string, payload: any) {
    if (!active) {
        console.info("FAKE ANALYTICS => identify", userId, payload);
        return;
    }
    // @ts-ignore
    if($crisp) {
        if(userId) {
            // @ts-ignore
            $crisp.push(["set", "user:id", [userId]])
        }
        if(payload?.email) {
            // @ts-ignore
            $crisp.push(["set", "user:email", [payload?.email]])
        }
    }

    window.amplitude.getInstance().setUserId(userId);
    window.amplitude.getInstance().setUserProperties(payload);
    window.Intercom("boot", { user_id: userId, ...payload });
}

export function traits(payload: any) {
    if (!active) {
        console.info("FAKE ANALYTICS => org", payload);
        return;
    }
    window.Intercom("update", payload);
}

export function page() {
    if (!active) {
        console.info("FAKE ANALYTICS => page");
        return;
    }
    window.Intercom("update");
}

export function setActive(newActive: boolean) {
    active = newActive;
}
