/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import {jsx} from '@emotion/react';
import axios from "axios";
import {Fragment, useContext, useEffect, useState} from "react";
import {AdWordsLoading, AxiosContext, track} from "@front-component";

const Style = styled.div`
margin: auto;
width:fit-content;
`;

export function GoogleLogout() {
    const {api}= useContext(AxiosContext)
    const [url,setUrl] = useState(undefined)
    async function load(){
            track("logout",{})
            const data = await api!.delete("/auth/login")
            window.location.href = "/login"
    }
    useEffect(() =>{
        load().then()
    },[])

    return(
        <Fragment>
            <AdWordsLoading loading={true}>
                <Fragment></Fragment>
            </AdWordsLoading>
        </Fragment>
    )
}
